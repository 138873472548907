import cx from 'classnames'
import PropTypes from 'prop-types'

/**
 * Button Component.
 *
 */
const Button = ({
  children,
  onClick,
  disabled,
  type = 'button',
  color = 'black',
  className = '',
  secondary = false,
  dataCy = ''
}) => {
  return (
    <button
      onClick={onClick && (() => onClick())}
      type={type}
      disabled={disabled}
      className={cx('btn', className, {
        [color]: !!color,
        secondary: secondary,
        'opacity-50 cursor-not-allowed': disabled
      })}
      data-cy={dataCy}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  dataCy: PropTypes.string
}

export default Button
