import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import styles from './gridWrapper.module.scss'
import { GridWrap } from '../types'

/**
 * Grid Wrapper Component.
 * @type {Reusable Component}
 * @param {String} gridSize @required props grid config size.
 * @param {String} children @required props for jsx elements that will be wrapped
 * @param {String} classNames @optional props for additional wrapper classNames.
 * @param {JSX.Element} wrapWith @optional props for wrapping element
 * @param {String} gridTop @optional props for top spacing
 * @param {String} gridBottom @optional props for bottom spacing
 * @param {String} gridLeft @optional props for left spacing
 * @param {String} gridRight @optional props for right spacing
 * @param {String} gridX @optional props for left and right spacing
 * @param {String} gridY @optional props for top and bottom spacing
 * @param {String} fallback @optional props for fallback to gridsize spacing if not mentioned in top, bottom, left and right sizes
 * @param {String} onclick @optional props for function onclick callback
 * @returns {JSX.Element}
 */
const GWrapMargin: FunctionComponent<GridWrap> = ({
  wrapWith,
  gridSize,
  gridX,
  gridY,
  gridTop,
  gridBottom,
  gridLeft,
  gridRight,
  fallBack = true,
  classNames,
  children,
  onClick
}) => {
  /** assigning grid values based on related size
   * @cssRules falls back to required gridSize
   */

  const top: string = gridTop || gridSize
  const bottom: string = gridBottom || gridSize
  const left: string = gridLeft || gridSize
  const right: string = gridRight || gridSize
  const topBottom: string = gridY || gridSize
  const leftBottom: string = gridX || gridSize

  const isAllSide =
    (!gridTop && !gridBottom && !gridLeft && !gridRight) ||
    (fallBack && top === bottom && left === right && top === left)
      ? true
      : false

  return React.createElement(wrapWith || 'div', {
    className: cx(
      {
        [styles[`d-margin__top-bottom__${topBottom}`]]:
          gridY || (fallBack && top === bottom && !isAllSide),
        [styles[`d-margin__left-right__${leftBottom}`]]:
          gridX || (fallBack && left === right && !isAllSide),
        [styles[`d-margin__top__${top}`]]: gridTop || (fallBack && top !== bottom && !isAllSide),
        [styles[`d-margin__bottom__${bottom}`]]:
          gridBottom || (fallBack && bottom !== top && !isAllSide),
        [styles[`d-margin__left__${left}`]]: gridLeft || (fallBack && left !== right && !isAllSide),
        [styles[`d-margin__right__${right}`]]:
          gridRight || (fallBack && right !== left && !isAllSide),
        [styles[`d-margin__all__${gridSize}`]]: fallBack && isAllSide
      },
      classNames
    ),
    children,
    onClick
  })
}

export default GWrapMargin
