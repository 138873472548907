import { gql } from '@apollo/client'

/**
 * Query to get Makes Options
 */
export const GET_TP_COMPARE_SPECS_MAKES = gql`
  query GET_TP_COMPARE_SPECS_MAKES($page: Int!, $perPage: Int!) {
    makes: Makes(
      paginate: { page: $page, pageSize: $perPage }
      where: { currentRelease: { eq: T } }
    ) {
      results {
        id
        slug
        makeableId
        label: description
        value: makeableUuid
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`

export const GET_TP_COMPARE_SPECS_MAKE_MODELS = gql`
  query GET_TP_COMPARE_SPECS_MAKE_MODELS($makeId: String) {
    models: Makes(where: { makeableUuid: { eq: $makeId } }) {
      results {
        id
        slug
        makeableId
        makeableUuid
        families(where: { currentRelease: { eq: T } }) {
          id
          type
          slug
          familyableId
          label: description
          value: familyableUuid
          slug
        }
      }
    }
  }
`

export const GET_TP_COMPARE_SPECS_BADGES_AND_VARIANTS = gql`
  query GET_TP_COMPARE_SPECS_BADGES_AND_VARIANTS($modelId: String) {
    badges: Families(where: { familyableUuid: { eq: $modelId } }) {
      results {
        id
        type
        slug
        familyableId
        familyableUuid
        vehicles(where: { currentRelease: { eq: T } }) {
          id
          type
          slug
          vehicleKey
          vehicleableId
          variantValue: vehicleableUuid
          attributes {
            id
            variantLabel: shortDescription
          }
          badge {
            badgeableUuid
            value: id
            label: badgeDescription
          }
        }
      }
    }
  }
`
