import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import logger from '../utils/logger'
import fetch from 'cross-fetch'
import accessMiddleware from './accessMiddleware'
import { getRuntimeEnv } from '../utils/functions/get-runtime-env'

const tailpipeEndpoint = getRuntimeEnv('NEXT_PUBLIC_TAILPIPE_URL')
const tailpipeMiddleware = accessMiddleware('tailpipe-link', tailpipeEndpoint)

export const tailpipeErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const queryName = operation?.operationName || 'UNNAMED_QUERY'
  const variables = operation?.variables ? JSON.stringify(operation?.variables) : '{}'

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const location = locations ? JSON.stringify(locations) : '-'
      logger.error(
        `[GraphQL error] - tailpipe-link.js ${queryName} [${variables}] [message:${message}], [location:${location}], [path:${
          path || '-'
        }]`
      )
    })
  }
  if (networkError) {
    logger.error(
      `[GraphQL error] - tailpipe-link.js ${queryName} - Network Error ${networkError.statusCode} ${networkError.message}`
    )
  }
})

export const tailpipeEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    // eslint-disable-next-line
    // operation.getContext().tpSpan?.finish()
    return data
  })
}).concat(tailpipeErrorLink)

const tailpipeHttpLink = createHttpLink({
  uri: tailpipeEndpoint,
  fetch: fetch
})

const composedLinks = from([tailpipeMiddleware, tailpipeEndLink, tailpipeHttpLink])
export default composedLinks
