import cx from 'classnames'
import { FunctionComponent, LegacyRef, memo, useRef } from 'react'
import Select, { createFilter, GroupBase, OptionsOrGroups } from 'react-select'
import { amendedComponents } from '@grille/components/common/react-select/amended-attribute-components'
import { Option } from '../../../types/findACar'
import customStylesReactSelect from './customStyles'
import DropdownIndicatorArrow from '../../common/dropdown/dropdownIndicatorArrow'
import styles from './dropdown.module.scss'
import { Color } from '../../../constants/colors'

/**
 * dropdown for make model search used in find a car feature
 * @type {Specific Component}
 * @param placeHolder default value to be displayed when no selected option
 * @param options options to be displayed on dropdowns
 * @param selectedOption selected option
 * @param onSelect function which is triggered when an option is selected
 * @param isDisabled wether dropdown is disabled
 * @param customSelectClass @optional class applies to select component
 * @param searchMake: predefined searchMake for model dropdowns, should be empty for make dropdown
 * @param getOptionLink function takes in make and model options to generate link for option for SEO support
 * @param idPrefix an id prefix for the select component
 * @param isSSR whether it is for SSR

 * @returns FunctionComponent
 */
const Dropdown: FunctionComponent<Props> = ({
  placeHolder = '',
  options = [],
  selectedOption,
  onSelect,
  isDisabled,
  customSelectClass,
  searchMake,
  getOptionLink,
  idPrefix,
  id,
  isSSR
}) => {
  const dropdownRef = useRef<HTMLDivElement>()
  const formatOption: FunctionComponent<Option> = (data: Option) => {
    const make: Option = searchMake ?? data
    const model = searchMake ? data : null
    const link = getOptionLink(make, model)
    return (
      <a href={link} className='select-none text-black pointer-events-none hover:text-black'>
        {data.label}
      </a>
    )
  }
  return (
    <div
      id={id}
      ref={dropdownRef as LegacyRef<HTMLDivElement>}
      className={cx(
        styles['d-find-a-car-dropdown'],
        'find-a-car-react-select',
        {
          'non-selected': !selectedOption
        },
        { [styles['d-find-a-car-dropdown--ssr']]: isSSR },
        {
          //extra styles apply when disabled
          [styles['d-find-a-car-dropdown--disabled']]: isDisabled
        }
      )}
    >
      <Select
        instanceId={idPrefix}
        formatOptionLabel={formatOption}
        isClearable
        menuIsOpen={isSSR ? true : undefined} //undefined value allows lib to handle
        placeholder={selectedOption?.label ?? placeHolder}
        components={{
          Input: amendedComponents.Input,
          Option: amendedComponents.Option,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          DropdownIndicator: () =>
            DropdownIndicatorArrow({
              isDisabled
            })
        }}
        className={cx('m-react-select', customSelectClass)}
        classNamePrefix='m-react-select'
        styles={customStylesReactSelect(selectedOption?.value ?? null, {
          hoverControl: Color.black,
          hoverDropdownIndicator: Color.black,
          optionBackground: Color.white,
          optionFocusedBackground: Color.lightBg,
          option: Color.blueDark,
          optionSelected: Color.black
        })}
        options={options}
        isDisabled={isDisabled}
        //@ts-ignore select does not require return from onChange
        onChange={(option: Option) => {
          onSelect(option)
        }}
        filterOption={createFilter({ matchFrom: 'start' })}
        //@ts-ignore select accepts string
        value={selectedOption?.label ?? ''}
      />
    </div>
  )
}

type Props = {
  placeHolder: string
  options: Option[] | OptionsOrGroups<any, GroupBase<any>>
  selectedOption: Option | null
  onSelect: (option: Option | null) => void
  isDisabled: boolean
  customSelectClass?: string
  searchMake: Option | null
  getOptionLink: (make: Option | null, model: Option | null) => string
  isSSR?: boolean
  idPrefix: string //once upgraded to React 18, we can use useId() hook to generate a hydration-safe id
  id?: string
}

export default memo(Dropdown)
