import logger from '../utils/logger'
import fetch from 'cross-fetch'
import { getRuntimeEnv } from './functions/get-runtime-env'

/**
 * @description
 * - Get json files from grille asset
 * - Environment dependent:
 *    - On dev/staging/prod it will be intercepted by Cloudflare and served from the assets folder on cloud
 *    - redirection is defined here: platform/terraform/templates/redirection_script.js
 *    - On local, it will point to public/grille-assets, and replace local grille domain with ''
 *      so that data is accessible without changing server's allowed origins
 * @param {String} fileName file name with extension eg. make.json
 * @returns json object or undefined
 */
export const getGrilleAsset = async ({
  fileName
}: Props): Promise<Record<string, any> | undefined> => {
  let res: Response | null = null
  let nextPublicDomain: string = (getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL') as string) ?? ''
  const frontendAliasPrefix = 'http://local-grille'
  //use relative path for local client side
  if (nextPublicDomain.startsWith(frontendAliasPrefix) && typeof window !== 'undefined') {
    nextPublicDomain = ''
  }
  const path: string = `${nextPublicDomain}/grille-assets/${fileName}`
  try {
    res = await fetch(path, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (res && res.status === 200) {
      const json = await res.json() //json can be undefined
      return json
    } else {
      return undefined
    }
  } catch (error) {
    logger.warn('get grille asset error', error)
  }
}

type Props = {
  fileName: string
}
