// util function for getting this value for datalayer and targeting
export const getIncludedAdFormats = ({ windowWidth }) => {
  let includedAdFormats = 'mb'
  if (windowWidth >= 768 && windowWidth <= 1023) {
    includedAdFormats = 'lb'
  } else if (windowWidth >= 1024 && windowWidth <= 1569) {
    includedAdFormats = 'bb,lb'
  } else if (windowWidth >= 1570) {
    includedAdFormats = 'bb,skin,lb'
  }

  return includedAdFormats
}
