import { useState, useEffect, useRef } from 'react'
import {
  TWO_LARGE_WINDOW_WIDTH,
  LARGE_WINDOW_WIDTH,
  MEDIUM_WINDOW_WIDTH,
  SMALL_WINDOW_WIDTH
} from '../../constants/index'

/**
 * - Custom hook to get the window width and size of window, does not detect resize, only send value once when mounted.
 * - Default value when windows is not ready is LARGE_WINDOW_WIDTH (1024px)
 * - Use it as: const {windowWidth, windowSize} = useWindowWidth<{windowWidth: number, windowSize: string}>()
 * @return {*}
 */
const useWindowWidth = () => {
  const mountedRef = useRef(false)
  const getSize = (width) => {
    if (width <= SMALL_WINDOW_WIDTH) {
      return 'small'
    } else if (width <= MEDIUM_WINDOW_WIDTH) {
      return 'medium'
    } else if (width <= LARGE_WINDOW_WIDTH) {
      return 'large'
    } else if (width <= TWO_LARGE_WINDOW_WIDTH) {
      return '2large'
    }

    return 'xlarge'
  }

  const [windowWidth, setWindowWidth] = useState({
    width: process.browser ? window.innerWidth : LARGE_WINDOW_WIDTH,
    size: getSize(process.browser ? window.innerWidth : LARGE_WINDOW_WIDTH)
  })

  useEffect(() => {
    // To fix memory leak in useEffect cleanup function.
    mountedRef.current = true
    if (mountedRef.current) {
      window.addEventListener(
        'resize',
        (event) =>
          setWindowWidth({
            width: event.currentTarget.innerWidth,
            size: getSize(event.currentTarget.innerWidth)
          }),
        { passive: true, once: true }
      )
    }

    return () => {
      window.removeEventListener('resize', null, true)
      mountedRef.current = false
    }
  }, [])

  return { windowWidth: windowWidth.width, windowSize: windowWidth.size }
}

export default useWindowWidth
