import { gql } from '@apollo/client'
import { CarsForSaleOptionFragment } from '../../fragments/cars-for-sale/index'

/**
 * Fetch available models
 */
export const GET_LISTING_MODELS_AVAILABLE = gql`
  query GET_LISTING_MODELS_AVAILABLE($where: WhereOptionsDealerListing!) {
    availableModels: DealerListingsCountBy(group: familyDescription, where: $where) {
      ${CarsForSaleOptionFragment}
    }
  }
`

/**
 * Get the cars for sale 'Models' without count from the Cars For Sale GraphQL API.
 */
export const GET_LISTING_MODELS_ALL = gql`
  query GET_LISTING_MODELS_ALL($where: WhereOptionsDealerListing!) {
    models: DealerListingsCountBy(group: familyDescription, where: $where) {
      ${CarsForSaleOptionFragment}
    }
  }
`

/**
 * @description
 * - get model description by make and model slug
 * - Make query is used as it supports both make and model slugs,
 *   while Family and Families do not support make slug.
 * - Make and model slugs combination is used to avoid model slug duplicates.
 */
export const GET_TP_MAKE_MODEL_DESCRIPTION = gql`
  query GET_TP_MAKE_MODEL_DESCRIPTION($familySlug: String, $makeSlug: String) {
    make: Make(where: { slug: $makeSlug }) {
      id
      makeableUuid
      slug
      families(where: { slug: { eq: $familySlug } }) {
        id
        slug
        familyableId
        familyableUuid
        description
      }
    }
  }
`
/**
 * Get Cars for sale available model options for ghost listings
 */
export const GET_GHOST_LISTING_MODELS_AVAILABLE = gql`
  query GET_GHOST_LISTING_MODELS_AVAILABLE($where: WhereOptionsGhostListing!) {
    availableGhostListingModels: GhostListingsCountBy(group: familyDescription, where: $where) {
      ${CarsForSaleOptionFragment}
    }
  }
`

export const GET_ALL_LISTING_MODELS_AVAILABLE = gql`
  query GET_ALL_LISTING_MODELS_AVAILABLE($dealerListingWhere: WhereOptionsDealerListing!, $ghostListingWhere: WhereOptionsGhostListing!) {
     availableModels: DealerListingsCountBy(group: familyDescription, where: $dealerListingWhere) {
      ${CarsForSaleOptionFragment}
    }
    availableGhostListingModels: GhostListingsCountBy(group: familyDescription, where: $ghostListingWhere) {
      ${CarsForSaleOptionFragment}
    }
  }
`
