import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import logger from '../utils/logger'
import parseBlockAttributes from '../utils/functions/parsing/parseBlockAttributes'
import parseJSONSafe from '../utils/functions/parsing/parseJSONSafe'
import unescape from 'lodash/unescape'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

const wordpressUri =
  getRuntimeEnv('NEXT_PUBLIC_WORDPRESS_API_URL') ||
  getRuntimeEnv('NEXT_PUBLIC_WORDPRESS_URL') + '/graphql'

import accessMiddleware from './accessMiddleware'
/**
 * Middleware operation for Wordpress
 */
const wordpressMiddleware = accessMiddleware('wordpress-link', wordpressUri, {
  setAuthHeaders: true
})

const wordpressHttpLink = createHttpLink({
  uri: wordpressUri,
  fetch: fetch
})

export const wordpressErrorsLink = onError(({ graphQLErrors, networkError, operation }) => {
  const queryName = operation?.operationName || 'UNNAMED_QUERY'
  const variables = operation?.variables ? JSON.stringify(operation?.variables) : '{}'
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const location = locations ? JSON.stringify(locations) : '-'
      logger.error(
        `[GraphQL error] - wordpress-link.js ${queryName} [${variables}] [message:${message}], [location:${location}], [path:${
          path || '-'
        }]`
      )
    })
  }
  if (networkError) {
    logger.error(
      `[GraphQL error] - wordpress-link.js ${queryName} - Network Error ${networkError.statusCode} ${networkError.message}`
    )
  }
})

const parseDataTargetingMedia = (part, key) => {
  if (part['dataLayer']) {
    part['dataLayer'] = parseJSONSafe(unescape(part['dataLayer']), `${key}.dataLayer`, [])
  }
  if (part['targeting']) {
    part['targeting'] = parseJSONSafe(unescape(part['targeting']), `${key}.targeting`)
  }
  if (part['mediaItems']) {
    part['mediaItems'] = parseJSONSafe(part['mediaItems'], `${key}.mediaItems`)
    if (part['mediaItems'] && part['mediaItems'].images) {
      part['mediaItems'].images = part['mediaItems'].images.filter((mediaItem) => {
        const validImage =
          (mediaItem.cloudinary &&
            mediaItem.cloudinary.publicId &&
            mediaItem.cloudinary.cloudName) ||
          (mediaItem.sourceUrl && mediaItem.sourceUrl.startsWith('http'))
        if (!validImage) {
          logger.warn(`Invalid image found in mediaItems: ${JSON.stringify(mediaItem)}`)
        }
        return validImage
      })
    }
  }
}

export const wordpressEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data?.data) {
      Object.keys(data.data)?.forEach((key) => {
        let part = data.data[key]
        // This can be null sometimes, for e.g data: { review: null } (page not found)
        if (part) {
          part?.blocks && parseBlockAttributes(part.blocks)
          part?.edges &&
            part?.edges?.forEach((edge) => {
              edge?.node?.blocks && parseBlockAttributes(edge.node.blocks)
            })
          part?.nodes &&
            part.nodes?.forEach((node) => {
              parseDataTargetingMedia(node, key)
              parseBlockAttributes(node?.blocks)
            })
          parseDataTargetingMedia(part, key)
        }
      })
    }
    return data
  })
}).concat(wordpressErrorsLink)

const composedLinks = from([wordpressMiddleware, wordpressEndLink, wordpressHttpLink])
export default composedLinks
