import { PostMetaWithAuthorCloudinary, TrendingPostMeta } from '../post-meta'
import DriveImageFragment from '../image'

const CarAdviceFragment = `
fragment CarAdviceFragment on CarAdvice {
  ${PostMetaWithAuthorCloudinary}
  categories: carAdviceSections(first: 1) {
    nodes {
      name
      id
      uri
    }
  }
}
${DriveImageFragment}
`

const TrendingCarAdviceFragment = `
fragment TrendingCarAdviceFragment on CarAdvice {
  ${TrendingPostMeta}
  categories: carAdviceSections(first: 1) {
    nodes {
      name
      id
      uri
    }
  }
}
${DriveImageFragment}
`

export default CarAdviceFragment
export { CarAdviceFragment, TrendingCarAdviceFragment }
