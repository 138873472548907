//Local storage keys
export const COMPARE_BUCKET_CACHE = 'COMPARE_BUCKET_CACHE'
export const NEXT_PUBLIC_COMPARE_BUCKET_VERSION = 'NEXT_PUBLIC_COMPARE_BUCKET_VERSION'

export const SMC_ENGINE_CACHE = 'SMC_DATA_CACHE'
export const NEXT_PUBLIC_SMC_DATA_VERSION = 'NEXT_PUBLIC_SMC_DATA_VERSION'

export const USER_ENGINE_CACHE = 'USER_ENGINE_CACHE'
export const NEXT_PUBLIC_USER_DATA_VERSION = 'NEXT_PUBLIC_USER_DATA_VERSION'

export const GEO_CACHE_KEY = 'driveau.geo'
export const GEO_CACHE_PERSIST_SECONDS = 86400

export const LOADED_BLOCKS_CACHE_KEY = 'loaded-blocks'
