/*
Used for News and CarAdvice
*/
import Disqus from './disqus'

const PostMeta = `
    id
    uri
    title
    date
    stickyPosts {
        stickyType
    }
    databaseId
    tags {
        nodes {
          id
          name
        }
    }
    image: featuredImage {
        node {
            ...DriveImageFragment
        }
    }
    author {
        node {
            name
            uri
            slug
            avatar {
                url
            }
        }
    }
    ${Disqus}
`

const PostMetaWithAuthorCloudinary = `
    uri
    id
    title
    date
    excerpt
    stickyPosts {
        stickyType
    }
    tags {
        nodes {
          id
          name
        }
    }
    image: featuredImage {
        node {
            ...DriveImageFragment
        }
    }
    author {
        node {
            name
            uri
            slug
            avatar {
                cloudinary {
                    cloudName
                    deliveryType
                    publicId
                }
                url
            }
        }
    }
    ${Disqus}    
`

//for trending news and reviews, does not include tags
const TrendingPostMeta = `
    uri
    title
    date
    stickyPosts {
        stickyType
    }
    image: featuredImage {
        node {
            ...DriveImageFragment
        }
    }
    ${Disqus}
`
export default PostMeta
export { TrendingPostMeta, PostMeta, PostMetaWithAuthorCloudinary }
