export const enum Color {
  'black' = '#000000',
  'white' = '#ffffff',

  'gold' = '#DBB52E',

  'blue1' = '#1f5ecf',
  'blueDark' = '#2960c5',
  'blueFaint' = '#d8eafe',
  'blueLight' = '#89c1fc',
  'blueNormal' = '#3b97fa',

  'coralDark' = '#d73f3c',
  'coralLight' = '#ff9e99',
  'coralNormal' = '#ff5e55',

  'gray1' = '#ebebeb',
  'gray100' = '#f7f7f7',
  'gray2' = '#8a8a8a',
  'gray200' = '#efefef',
  'gray3' = '#f1f1f1',
  'gray300' = '#e3e3e3',
  'gray400' = '#a7a7a7',
  'gray500' = '#666666',
  'gray600' = '#3f3f3f',

  'lightBg' = '#f7f7f7',
  'lightBlue' = '#e7f7f7',

  'tealDark' = '#007b79',
  'tealFaint' = '#cceded',
  'tealLight' = '#66cac8',
  'tealNormal' = '#00a6a4'
}
