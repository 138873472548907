export interface PubSubStatic {
  publish: (channel: string, message: any) => void
  subscribe: (channel: string, listener: (result: any) => void) => void
}

type TpubSubOptions = {
  allowMultiSubscribers: boolean
}

/**
 * @class PubSub
 * @reason needed to publish and subscribe data based on pub sub design pattern for more efficient asynchronous update
 * @description does not support multi subscribers of the same channel. 1:1 relation is kept to maintain clear data flow.
 * @export {Object}               @required contains publisher and subscriber
 * @param {Subscribe}             @required channel name and listener callback function
 * @param {publish}               @required channel name and message as {Object} to publish
 */

class PubSub {
  private readonly subscribers: { [key: string]: any[] }
  constructor(
    private readonly options: TpubSubOptions = {
      allowMultiSubscribers: false
    }
  ) {
    this.subscribers = {}
  }

  private subscribe = (channel: string, message: (result: Record<string, any>) => any): void => {
    if (!Array.isArray(this.subscribers[channel])) {
      this.subscribers[channel] = []
    }

    if (!this.options.allowMultiSubscribers) {
      /* reset to last subscriber
       * only allow 1 subscriber per channel
       */
      this.subscribers[channel] = []
    }
    this.subscribers[channel].push(message)
  }

  private publish = (channel: string, message: Record<string, any>): void => {
    const channelSubscribers = this.subscribers[channel] || []
    if (channelSubscribers.length) {
      channelSubscribers.forEach((channelSubscriber) => channelSubscriber(message))
    }
  }

  public export = (): PubSubStatic => ({
    publish: this.publish,
    subscribe: this.subscribe
  })
}

export default PubSub
