import { PostMetaWithAuthorCloudinary } from '../post-meta'
import DriveImageFragment from '../image'

const NewsFragment = `
fragment NewsFragment on News {
  ${PostMetaWithAuthorCloudinary}
  categories: newsTopics(first: 1) {
    nodes {
      name
      id
      uri
    }
  }
}
 ${DriveImageFragment}
`

export default NewsFragment
