import cx from 'classnames'
import PropTypes from 'prop-types'
import Image from '../../common/image'
import { getHeroImage, getPlaceholderImage } from '../../../utils/functions/images'
import isEmpty from 'lodash/isEmpty'
import { PlayIconRounded } from '../../../components/icons'
import styles from './thumbnailImage.module.scss'

/**
 * Button Component.
 *
 * @param {Object, Object, String, String, Boolean} { image, object, postTitle, className, compact } as props
 * @returns {JSX.Element}
 */
const ThumbnailImage = ({
  image,
  object,
  postTitle,
  customIconClassName,
  altText,
  onClick,
  compact = false,
  className = 'relative flex h-full items-center',
  showDefault = true,
  sizeName = '',
  isCarsForSaleDefaultImage = false,
  imageLoading = 'lazy'
}) => {
  if (isEmpty(image) && !showDefault) {
    return null
  }

  let heroImage = image
  let placeholderImage = null
  if (image?.fileType !== undefined) {
    if (image?.fileType === 'video' && 'mediumPoster' in image) {
      heroImage = image?.mediumPoster
    } else {
      heroImage = getHeroImage(image, object, compact)
    }
    placeholderImage =
      image?.fileType === 'image' ? getPlaceholderImage(image, object) : image?.thumbnailPoster
  }

  const imageProps = {
    ...heroImage,
    sourceUrl: heroImage?.sourceUrl || null, // keep this here to show images when sourceUrl is empty
    cloudinary: heroImage?.cloudinary,
    postTitle: postTitle,
    placeholder: placeholderImage,
    size: compact ? 'thumbnail' : 'medium_thumbnail',
    className: compact ? 'w-full object-cover h-full' : image?.className,
    showDefault: true,
    altText: altText,
    loading: image?.loading ?? imageLoading,
    width: image?.width ?? 'auto',
    height: image?.height ?? 'auto',
    fetchpriority: image?.fetchpriority ?? undefined
  }

  return (
    <span className={className} onClick={onClick}>
      <Image
        {...imageProps}
        sizeName={sizeName}
        isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
      />

      {image?.fileType === 'video' ||
        (image?.playIcon && (
          <span className={cx(styles['drive-thumbnail'])}>
            <PlayIconRounded
              height={!compact ? 100 : 40}
              className={cx(styles[customIconClassName ?? 'drive-thumbnail__icon'], {
                [cx(styles['drive-thumbnail__icon-no-compact'])]: compact,
                [cx(styles['drive-thumbnail__icon-compact'])]: !compact
              })}
            />
          </span>
        ))}
    </span>
  )
}

ThumbnailImage.propTypes = {
  image: PropTypes.object,
  object: PropTypes.object,
  postTitle: PropTypes.string,
  className: PropTypes.string,
  altText: PropTypes.string,
  customIconClassName: PropTypes.string,
  sizeName: PropTypes.string,
  compact: PropTypes.bool,
  showDefault: PropTypes.bool,
  isCarsForSaleDefaultImage: PropTypes.bool,
  onClick: PropTypes.func,
  imageLoading: PropTypes.oneOf(['lazy', 'eager']),
  altText: PropTypes.string
}

export default ThumbnailImage
