import { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import { useScrollPosition } from '../../../utils/hooks/useScrollPosition'
import useWindowWidth from '../../../utils/hooks/use-window-width'
import { MOBILE_DEVICE_SIZES } from '../../../constants'
import styles from './headerMenu.module.scss'
import cx from 'classnames'
import { PubSubStatic } from '../../../utils/class/PubSub'
import { NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS } from '../../../constants/notification-banner'

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  isPanelOpened,
  mobilePersistStickyHeader,
  pagePubSub,
  children
}) => {
  //sticky due to scrolling up
  const [scrollUpSticky, setScrollUpSticky] = useState<boolean>(false)
  const topDistance: number = 200
  const { windowSize }: { windowSize: string } = useWindowWidth()
  const isMobileDevice: boolean = MOBILE_DEVICE_SIZES.includes(windowSize)
  const isMounted = useRef<boolean>(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useScrollPosition(
    /*mobile nav shows when scrolling up */
    ({ prevPos, currPos }: Positions) => {
      const scrollingUp: boolean = currPos.y < prevPos.y
      if (mobilePersistStickyHeader) {
        setScrollUpSticky(true)
        pagePubSub?.publish(NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS, true)
        return
      }

      if (isMobileDevice && (isPanelOpened || (scrollingUp && Math.abs(currPos.y) > topDistance))) {
        setScrollUpSticky(true)
        pagePubSub?.publish(NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS, true)
        return
      }

      setScrollUpSticky(false)
      pagePubSub?.publish(NOTIFICATION_MAIN_NAVIGATION_STICKY_STATUS, false)
    },
    null,
    null,
    true,
    100
  )
  return (
    <>
      {/*static nav for mobile to avoid jumpy transition to sticky*/}
      <div
        id='drive-header-menu'
        className={cx(styles['drive-navbar__header-menu'], {
          [styles['drive-navbar__header-menu--sticky']]: scrollUpSticky || isPanelOpened,
          //separate class is used as useReponsive width is not reliable
          [styles['drive-navbar__header-menu--mobile-sticky']]: mobilePersistStickyHeader
        })}
      >
        {children}
      </div>
    </>
  )
}

export default HeaderMenu

type HeaderMenuProps = {
  mobilePersistStickyHeader: boolean
  isPanelOpened: boolean
  pagePubSub?: PubSubStatic | null
  children: ReactNode
}

type Position = {
  x: number
  y: number
}

type Positions = {
  prevPos: Position
  currPos: Position
}
