import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import logger from '../utils/logger'
import fetch from 'cross-fetch'
import accessMiddleware from './accessMiddleware'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

const carsForSaleEndpoint = getRuntimeEnv('NEXT_PUBLIC_CARSFORSALE_URL')
const carsForSaleMiddleware = accessMiddleware('listings-link', carsForSaleEndpoint)

const numericKeys = ['regionId', 'priceEgc', 'priceIgc', 'year', 'odometer']

const keysToParse = [
  'where',
  'newDemoCarsQuery',
  'usedCarsQuery',
  'newDemoQuery',
  'usedQuery',
  'newFallback',
  'usedFallback'
]

export const parseCriteria = (item) => {
  if (item !== null && isObject(item) && !isArray(item)) {
    let result = {}
    Object.entries(item).forEach(([k, v]) => {
      if (numericKeys.includes(k) || ['lt', 'gt', 'lte', 'gte', 'between'].includes(k)) {
        if (isObject(v) && !isArray(v)) {
          let subResult = {}
          Object.entries(v).forEach(([kk, vv]) => {
            subResult[kk] = isArray(vv) ? vv.map((n) => parseInt(n)) : parseInt(vv)
          })
          result[k] = subResult
        } else {
          result[k] = isArray(v) ? v.map((n) => parseInt(v)) : { eq: parseInt(v) }
        }
      } else if (isArray(v)) {
        const vv = v.map((n) => parseCriteria(n))
        result[k] = k !== 'or' && k !== 'and' ? { in: vv } : vv
      } else {
        result[k] = isString(v) ? { eq: v } : v
      }
    })
    return result
  } else if (isArray(item)) {
    return {
      in: item.map((i) => {
        return parseCriteria(i)
      })
    }
  } else {
    return item
  }
}

export const parseWhereVariables = (variables) => {
  !isEmpty(variables) &&
    Object.keys(variables)
      .filter((key) => keysToParse.includes(key))
      .forEach((key) => {
        const where = variables[key]
        if (!isEmpty(where)) {
          let parsedWhere = isString(where) ? JSON.parse(where) : { ...where }
          variables[key] = parseCriteria(parsedWhere)
        }
      })
  return variables
}

const queryFormatterLink = new ApolloLink((operation, forward) => {
  operation.variables = parseWhereVariables({ ...(operation.variables ?? {}) })
  return forward(operation)
})

export const carsForSaleErrorsLink = onError(({ graphQLErrors, networkError, operation }) => {
  const queryName = operation?.operationName || 'UNNAMED_QUERY'
  const variables = operation?.variables ? JSON.stringify(operation?.variables) : '{}'
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const location = locations ? JSON.stringify(locations) : '-'
      logger.error(
        `[GraphQL error] - listings-link.js ${queryName} [${variables}] [message:${message}], [location:${location}], [path:${
          path || '-'
        }]`
      )
    })
  }
  if (networkError) {
    logger.error(
      `[GraphQL error] - listings-link.js ${queryName} - Network Error ${networkError.statusCode} ${networkError.message}`
    )
  }
})

export const carsForSaleEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    return data
  })
}).concat(carsForSaleErrorsLink)

const carsForSaleHttpLink = createHttpLink({
  uri: carsForSaleEndpoint,
  fetch: fetch
})

const listingLinks = from([
  carsForSaleMiddleware,
  carsForSaleEndLink,
  queryFormatterLink,
  carsForSaleHttpLink
])
export default listingLinks
