import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

/**
 * @description
 *  - The structure of the header menu has changed; colOne colTwo colThree are removed and only have first level menu items.
 *  - Keeping the extra code for backward design compatible
 * @param {*} items
 * @returns
 */
export const normalizeHeaderMenuData = (items: Item[]) => {
  const menus =
    !isEmpty(items) && items.length
      ? items.map((menuItem: Item) => {
          let childItems: ChildItems = {}
          !isEmpty(menuItem?.node?.childItems) &&
            menuItem.node.childItems.edges?.map((item: Edge) => {
              let key: string = 'main'
              const items: FomattedMenu[] = []
              if (includes(item?.node?.url, '//col-')) {
                if (includes(item?.node?.url, '//col-one')) {
                  key = 'colOne'
                } else if (includes(item?.node?.url, '//col-two')) {
                  key = 'colTwo'
                } else if (includes(item?.node?.url, '//col-three')) {
                  key = 'colThree'
                } else if (includes(item?.node?.url, '//col-four')) {
                  key = 'colFour'
                }

                !isEmpty(item?.node?.childItems) &&
                  item?.node?.childItems?.edges.map((item: Edge) => {
                    let icon: string | undefined
                    if (!isEmpty(item?.node?.cssClasses)) {
                      if (item?.node?.cssClasses) {
                        icon = item?.node?.cssClasses[0]
                      }
                    }

                    items.push({
                      url: item?.node?.url,
                      path: item?.node?.path?.includes('/car-type/')
                        ? `/showrooms${item?.node?.path}`
                        : item?.node?.path,
                      label: item?.node?.label,
                      title: item?.node?.title,
                      icon: icon,
                      post: !isEmpty(item?.node?.connectedNode?.node)
                        ? item?.node?.connectedNode?.node
                        : undefined
                    })
                  })

                childItems = {
                  ...childItems,
                  [key]: {
                    items,
                    header: item?.node?.label,
                    journalists:
                      !isEmpty(item?.node?.journalistMenuItems?.journalistMenus) &&
                      item?.node?.journalistMenuItems?.journalistMenus
                  }
                }
              } else {
                if (childItems.main) {
                  childItems.main.push({
                    label: item?.node?.label,
                    url: item?.node?.url,
                    path: item?.node?.path,
                    title: item?.node?.title
                  })
                } else {
                  childItems = {
                    ...childItems,
                    main: [
                      {
                        label: item?.node?.label,
                        url: item?.node?.url,
                        path: item?.node?.path,
                        title: item?.node?.title
                      }
                    ]
                  }
                }
              }
            })

          return {
            label: menuItem?.node.label,
            url: menuItem?.node.url,
            path: menuItem?.node.path,
            title: menuItem?.node.title,
            cssClasses: menuItem?.node.cssClasses,
            childItems: !isEmpty(childItems) && childItems
          }
        })
      : []
  return menus
}

/**
 * @description
 *  - takes in raw data from graph query response.
 *  - currently the graphql response contains a 2 level menu.
 *  - first and second level have different raw data structures, not recursable.
 * @param rawMenuData raw data from response
 * @returns {label, url, path, title, subMenus}[]
 */
export const normalizeSideMenuData = (rawMenuData: Item[]) => {
  if (!rawMenuData?.length) return []
  const menus = rawMenuData.map(({ node }) => {
    const menu: FomattedMenu = {
      label: node?.label ?? '',
      url: node?.url ?? '',
      path: node?.path,
      title: node?.title ?? ''
    }
    // childItems is not an array, it is wrapper of edges in the graphql structure
    // 0 edges means no submenu
    menu['subMenus'] = node?.childItems?.edges?.map(({ node }: Edge) => {
      return {
        label: node?.label ?? '',
        url: node?.url ?? '',
        path: node?.path ?? '',
        title: node?.title ?? ''
      }
    })
    return menu
  })
  return menus
}

type FomattedMenu = {
  url?: string
  path?: string
  label?: string
  title?: string
  icon?: string
  post?: {
    [key: string]: any
  }
  subMenus?: FomattedMenu[]
}

type Node = {
  url?: string
  path?: string
  label?: string
  title?: string
  childItems?: ChildItems
  journalistMenuItems?: { [key: string]: any }
  cssClasses?: string[]
  connectedNode?: {
    node?: Node
  }
}

type ChildItems = {
  [key: string]: any
}

type Edge = {
  node?: Node
  [key: string]: any
}

type Item = {
  [key: string]: any
}
