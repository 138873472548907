import isEmpty from 'lodash/isEmpty'
import logger from '@grille/utils/logger'

/**
 * @class DriveCache
 * @info utility class to store persistent or non persistent data
 * @requirements Window global browser must be available for persistent data
 * @uses LocalStorage
 * @reason needed to build drive cache system that could be in memory or local storage persistent data cache
 * @param {Record<string, any>} cache  @optional initial cache
 * @param {TDriveCacheOptions} options    @optional
 * @param {TDriveCacheOptions['cacheKey']} option.cacheKey   @required cache key
 */
export class DriveCache implements IDriveCache {
  /**
   * @constructor
   */
  constructor(private cache: Record<string, any> = {}, private options?: TDriveCacheOptions) {
    if (typeof window !== 'undefined' && this.options?.persist && this.options?.cacheKey) {
      if (!this.isLocalStorageAvailable()) {
        this.cache = cache
        /* update the persist to false
         * making sure it will not call localStorage anymore
         */
        this.options.persist = false
        return
      }

      try {
        /* on load - retrieve stored cache from local storage if any
         * fall back to params initial cache
         */
        this.cache = JSON.parse(
          window.localStorage.getItem(this.options.cacheKey) || `${JSON.stringify(cache)}`
        )
      } catch (error) {
        logger.error(
          `Error - setting localstorage of ${this.options.cacheKey}`,
          JSON.stringify({ error })
        )
        this.cache = cache
      }
      this.initialiseCache()
    }
  }

  /**
   * @info    checks if cache is expired
   * @method  Private
   * @returns void
   */
  private isCacheExpired = (): boolean => {
    let hasExpired = false
    const storedTimeStamp = this.cache?.timeStamp ?? 0
    const persistTimeInSec = this.options?.persistTimeInSec
      ? /* supporting debugging cache time */
        this.options?.debugCacheTime
        ? this.options.debugCacheTime * 1000
        : this.options.persistTimeInSec * 1000
      : 0
    if (storedTimeStamp > 0 && persistTimeInSec > 0) {
      hasExpired = persistTimeInSec < new Date().getTime() - storedTimeStamp
    }
    return hasExpired
  }

  /**
   * @info    initialises cache
   *          used when the cache needs to be persistent
   * @method  Private
   * @returns void
   */
  private initialiseCache = (): void => {
    if (!isEmpty(this.cache)) {
      /* check if persist for certain time */
      if (this.isCacheExpired()) {
        this.cache = {}
        this.options?.cacheKey && window.localStorage.removeItem(this.options.cacheKey)
      } else {
        this.updateLocalStorage()
      }
    } else {
      this.updateLocalStorage()
    }
  }

  /**
   * @info    updates or sets new cache to localStorage
   * @method  Private
   * @returns void
   */
  private updateLocalStorage = (resetTimeStamp = false): void => {
    if (this.options?.persist && typeof window !== 'undefined' && this.options?.cacheKey) {
      try {
        this.cache.timeStamp = resetTimeStamp
          ? new Date().getTime()
          : this.cache?.timeStamp ?? new Date().getTime()
        window?.localStorage?.setItem(this.options.cacheKey, JSON.stringify(this.get()))
      } catch (error) {
        logger.error(
          `Error - setting localstorage of ${this.options.cacheKey}`,
          JSON.stringify({ error })
        )
      }
    }
  }

  /**
   * @info    gets current cache by key
   * @method  Public
   * @returns value by requested key. If key is not provided, it will give all the value by the cacheKey
   */
  public get = (key?: string | undefined) => {
    if (!isEmpty(key) && typeof key !== 'undefined') {
      return this.cache?.[key] ?? null
    }
    return this.cache
  }

  /**
   * @info    sets the current cache by key
   * @method  Public
   * @returns value of the key that has be just set
   */
  public set = (key: string, value: any, resetTimeStamp: boolean = false) => {
    this.cache[key] = value
    this.updateLocalStorage(resetTimeStamp)
    return this.cache[key]
  }

  /**
   * @info    resets the cache with or without given data
   * @param { Record<string, any>} data @optional
   * @method  Public
   * @returns { Record<string, any>}
   */
  public reset = (data: Record<string, any> = {}) => {
    this.cache = data
    this.updateLocalStorage(true)
    return this.cache
  }

  /**
   * @info   delete the cache with key
   * @param { key} string @required
   * @method  Public
   * @returns { Record<string, any>}
   */
  public delete = (key: string) => {
    const currentCache = this.cache
    delete currentCache?.[key]
    this.cache = currentCache

    this.updateLocalStorage()
    return this.cache
  }

  /**
   * @info checks if localStorage API is available or not
   * using modernizr approach
   */
  public isLocalStorageAvailable = () => {
    const test = 'drive_storage_test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (error) {
      logger.error(`Error - localStorage is not available`, JSON.stringify({ error }))
      return false
    }
  }
}

/**
 * @info    creates an instance of the DriveCache
 * @param { Record<string, any>} cache @optional
 * @param { TDriveCacheOptions} options @optional
 * @returns {IDriveCache}
 */
export const createDriveCache = (
  cache: Record<string, any>,
  options?: TDriveCacheOptions
): IDriveCache => new DriveCache(cache, options)

/**
 * @info    retrives the instance of the DriveCache
 * @param { string } cacheKey @required
 * @returns {IDriveCache}
 */
export const getDriveCache = (cacheKey: string, persistTimeInSec: number) =>
  createDriveCache({}, { persist: true, cacheKey: cacheKey, persistTimeInSec: persistTimeInSec })

export type TDriveCacheOptions = {
  persist: boolean
  cacheKey: string
  persistTimeInSec?: number
  debugCacheTime?: number
}
export interface IDriveCache {
  get: (key?: string) => any
  set: (key: string, value: any, resetTimeStamp?: boolean) => any | void
  reset?: (data?: Record<string, any>) => Record<string, any>
  delete: (key: string) => any
  isLocalStorageAvailable: () => boolean
}
