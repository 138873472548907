import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Link, { linkDefaultProps } from '../../common/link'
import { FunctionComponent, useMemo, useState } from 'react'

import { normalizeSideMenuData } from '../../../utils/functions/menu'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
import styles from './sideMenu.module.scss'
import { Color } from '../../../constants/colors'
import Icon from '../../common/icon'
import SideMenuFeaturedAd from './SideMenuFeaturedAd'
import GridWrapper from '../../containers/gridWrapper'

const isTest = isTestEnv()
/**
 * @description
 *  - displays hamburger menu data in tree navigation structure.
 *  - only one menu item can be opened at a time
 *  - openMenuIndex only supports 2 levels node tree structure, need to introduce id for tracking if expects more than 2 levels.
 *    ie. normalizeMenuData should also return id
 * @returns
 */
const SideMenu: FunctionComponent<SideMenuProps> = ({ menuData = [], isOpen = false }) => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number>(-1)
  const featuredAdId: string = 'gam_desknavcomp_pos1'
  const memoizedItems = useMemo<MenuItem[]>(() => normalizeSideMenuData(menuData), [menuData])

  const toggleMenuItem = (index: number) => {
    index === openMenuIndex
      ? setOpenMenuIndex(-1) // clear
      : setOpenMenuIndex(index) // replace
  }

  // takes in menuItem, render header and children
  const SideMenuItem: FunctionComponent<SideMenuItemProps> = ({ menuItem, index, isExpanded }) => {
    let { label, title, path, subMenus } = menuItem
    const hasSubMenu: boolean = !isEmpty(subMenus)

    let isJumpLinkOrHashFragment: boolean = false
    if (!isEmpty(title)) {
      path = path + '#' + title
      isJumpLinkOrHashFragment = true
    }

    return (
      <div
        className={cx(cx(styles['drive-side-menu__item']), {
          [cx(styles['drive-side-menu__item--parent'])]: subMenus
        })}
      >
        <div className={cx(styles['drive-side-menu__item__header'])}>
          <div className={cx(styles['drive-side-menu__item__link'])}>
            <Link
              {...linkDefaultProps}
              href={path as string}
              isJumpLinkOrHashFragment={isJumpLinkOrHashFragment}
            >
              <a role='menuitem' className={cx(styles['drive-side-menu__item__link__text'])}>
                {label}
              </a>
            </Link>
          </div>
          {/* show open/close arrow */}
          {hasSubMenu && (
            <span
              onClick={() => toggleMenuItem(index)}
              data-testid={isTest ? 'toggle-icon' : undefined}
              className={cx(styles['drive-side-menu__item__submenu-toggle'])}
            >
              {/* radio input supports single expend of menuitem when js is diabled,
                  state control takes over toggle when js is enabled
               */}
              <label htmlFor={`radio-${index}`}>
                <Icon
                  name={isExpanded ? 'ExpandLessIcon' : 'ExpandMoreIcon'}
                  height='24'
                  width='24'
                  color={Color.white}
                  hoverColor={Color.blueNormal}
                  className={cx(styles['drive-side-menu__item__submenu-toggle__icon'])}
                />
              </label>
            </span>
          )}
        </div>

        <input type='radio' id={`radio-${index}`} name='side-menu-item'></input>
        <div
          className={cx(styles['drive-side-menu__item__submenus'], {
            [cx(styles['drive-side-menu__item__submenus--opened'])]: isExpanded && hasSubMenu
          })}
          data-testid={isTest && isExpanded && hasSubMenu ? 'opened-submenu' : undefined}
        >
          {subMenus?.map((subMenuItem: MenuItem, subMenuIndex: number) => {
            return (
              <SideMenuItem
                index={index}
                key={`${index}-${subMenuIndex}-${path}`}
                menuItem={subMenuItem}
              />
            )
          })}
          {/* makes sure it has submenus */}
          {path === '/showrooms/' && hasSubMenu && isOpen && (
            <SideMenuFeaturedAd
              classNames='drive-side-menu__item__featured-ad'
              slot={featuredAdId}
              sidemenuAdKey='showrooms'
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx(styles['drive-side-menu-wrapper'])}
      data-testid={isTest ? 'side-menu-wrapper' : undefined}
    >
      <GridWrapper gridSize='grid2s'>
        <div className={cx([styles['drive-side-menu-content']])}>
          <div className={cx(styles['drive-side-menu'])}>
            <div role='menubar' className={cx(styles['drive-side-menu__items'])}>
              {memoizedItems?.length &&
                memoizedItems.map((menuItem: MenuItem, index: number) => (
                  <SideMenuItem
                    key={`sidemenu-${index}`}
                    index={index}
                    menuItem={menuItem}
                    isExpanded={openMenuIndex === index}
                  />
                ))}
            </div>
          </div>
        </div>
      </GridWrapper>
    </div>
  )
}

export default SideMenu

type MenuItem = {
  label?: string
  title?: string
  path?: string
  subMenus?: MenuItem[]
  [key: string]: any
}

type SideMenuItemProps = {
  menuItem: MenuItem
  index: number
  isExpanded?: boolean
}

type SideMenuProps = {
  menuData: {
    [key: string]: any
  }[]
  navigationAdUnits?: any
  isOpen?: boolean
}
