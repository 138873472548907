import { gql } from '@apollo/client'

import NewsFragment from '../fragments/news'
import ReviewsFragment from '../fragments/reviews/review'
import CarAdviceFragment from '../fragments/car-advice'

export const GET_RELATED_NEWS_AD_UNIT = gql`
  query GET_RELATED_NEWS_AD_UNIT($uri: String) {
    post: newsBy(uri: $uri) {
      excerpt
      ...NewsFragment
    }
  }
  ${NewsFragment}
`

export const GET_RELATED_REVIEWS_AD_UNIT = gql`
  query GET_RELATED_REVIEWS_AD_UNIT($uri: String) {
    post: reviewBy(uri: $uri) {
      excerpt
      ...ReviewsFragment
    }
  }
  ${ReviewsFragment}
`

export const GET_RELATED_CAR_ADVICES_AD_UNIT = gql`
  query GET_RELATED_CAR_ADVICES_AD_UNIT($uri: String) {
    post: carAdviceBy(uri: $uri) {
      excerpt
      ...CarAdviceFragment
    }
  }
  ${CarAdviceFragment}
`
export const GET_SHOWROOMS_MODEL_SPONSORED = gql`
  query GET_RELATED_SHOWROOMS_AD_UNIT($uri: ID!) {
    post: vehicleModelPost(id: $uri, idType: URI) {
      id
      title
      slug
      uri
      uuid
      blocks {
        name
        originalContent
      }
      image: featuredImage {
        id
        altText
        caption
        sourceUrl
        cloudinary {
          cloudName
          publicId
        }
      }
    }
  }
`
