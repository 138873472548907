import { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import logger from '../../logger'
import { getGrilleAsset } from '@grille/utils/getGrilleAsset'
import { ShowroomMakesData, Make, VehicleMakePost, ListingMakesData } from '../../../types/findACar'

/**
 * @description hook for getting popular makes for listing and showroom search used by find a car component
 * @param listingMakeData fetched data for cfs listing make
 * @param showroomMakesData  fetched data for showroom make
 * @return object which contains both showroom and listing makes.
 */
export const useFindACarPopularMakes = ({ listingMakeData, showroomMakesData }: Props) => {
  const [popularMakes, setPopularMakes] = useState<{ showroom: Make[]; listing: Make[] }>({
    showroom: [],
    listing: []
  })

  useEffect(() => {
    //popularmakes with logic to avoids empty slugs from backend
    const addPopularMakes = async () => {
      const data = await getGrilleAsset({
        fileName: 'popular-makes.json'
      })

      if (!isEmpty(data)) {
        const popularSlugSet: Set<string> = new Set<string>([])
        data?.vehicleMakePosts?.nodes?.forEach((post: VehicleMakePost) => {
          if (post?.isPopular === 'yes' && !isEmpty(post?.slug)) {
            popularSlugSet.add(post.slug)
          }
        })
        const newShowroomPopularMakes: Make[] = (showroomMakesData?.makes ?? []).filter(
          (make: Make) => !isEmpty(make?.slug) && popularSlugSet.has(make?.slug)
        )
        const newListingPopularMakes: Make[] = (
          listingMakeData?.availableMakes?.options ?? []
        ).reduce(
          (list, { name, slug }: { name: string; slug: string }) =>
            !isEmpty(slug) && popularSlugSet.has(slug)
              ? [...list, { label: name, value: slug, slug }]
              : list,
          [] as Make[]
        )
        // only setState when component is mounted
        setPopularMakes({ showroom: newShowroomPopularMakes, listing: newListingPopularMakes })
      } else {
        logger.error('Error fetching popular makes')
      }
    }

    if (isEmpty(popularMakes.listing) && isEmpty(popularMakes.showroom)) {
      if (showroomMakesData && listingMakeData) {
        addPopularMakes()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showroomMakesData, listingMakeData]) // only once

  return popularMakes
}

type Props = {
  listingMakeData?: ListingMakesData
  showroomMakesData?: ShowroomMakesData
}
