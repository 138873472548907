export const getHero = (includeGrid = false) => `
  hero {
    fileType
    video
    ${includeGrid ? 'gridType' : ''}
    large: gallery {
      ...DriveImageFragment
    }
    mediumThumbnail: gallery {
      ...DriveImageFragment
    }
    thumbnail: gallery {
      ...DriveImageFragment
    }
    largePoster: posterImage {
      ...DriveImageFragment
    }
    mediumPoster: posterImage {
      ...DriveImageFragment
    }
    thumbnailPoster: posterImage {
      ...DriveImageFragment
    }
  }
`

export default getHero()
