import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import logger from '../logger'
import { makeUrl } from '../imageHelper'

import {
  IMAGES_BREAKPOINT,
  CLOUDINARY_CLOUD_NAME,
  IMAGES_SIZES,
  IMAGES_FOLDERS,
  DEFAULT_CLOUDINARY_CLOUD_NAME
} from '../../constants/media'

/**
 * Get the image size from sizes array.
 *
 * @param {Array} sizes Sizes array e.g.[{name: "medium", height: "200", width: "300",}, {name: "large", height: "683", width: "1024"}]
 * @param {String} name Size Name.
 * @returns {Object} Image Object e.g. { name:"large", height:"683", width:"1024"}
 */
export const getImageSize = (sizes, name) => {
  if (isEmpty(sizes) || !sizes?.length || isEmpty(name)) {
    return {}
  }

  let imageSize = {}
  sizes.forEach((size) => {
    if (name === size?.name) {
      imageSize = size
    }
  })

  return imageSize
}

/**
 * Get hero image
 *
 * @param {Object} hero Hero image.
 * @param {Object} review Review.
 * @param {boolean} compact Compact
 * @param {boolean} single Single or Array Format (optional)
 * @param {String} size Size (optional)
 *
 * @returns {null|*} Hero image.
 */
export const getHeroImage = (hero, review, compact, single = true, size = 'mediumThumbnail') => {
  if (compact) {
    return review?.thumbnail ? review?.thumbnail?.node : hero?.mediumThumbnail[0]
  } else if (review?.[size]) {
    return review?.[size]?.node
  } else if (hero?.[size]) {
    return single && Array.isArray(hero?.[size]) ? hero?.[size][0] : hero?.[size]
  }
  return hero
}

/**
 * Get Placeholder image.
 *
 * @param {Object} hero Hero image.
 * @param {Object} review Review.
 *
 * @returns {null|*} Placeholder image.
 */
export const getPlaceholderImage = (hero, review) => {
  if (review?.mediumThumbnail) {
    return review?.thumbnail?.node
  } else if (hero?.thumbnail) {
    return hero?.thumbnail[0]
  }
  return null
}

/**
 * Get gallery captions from the blocks.
 *
 * @param {Array} images Images.
 *
 * @return captions Captions with image id and caption value e.g { 233: "my-caption" }
 */
export const getGalleryCaptions = (images) => {
  if (isEmpty(images) || !Array.isArray(images)) {
    return null
  }

  const captions = {}
  images.forEach((image) => {
    captions[image?.id] = image?.caption
  })

  return captions
}

/**
 * Get Image URL from Redbook PhotoID
 * @param {*} vehicleTypeCode Redbook Vehicle Type Code PS | LC | SV | HC
 * @param {*} photoId
 * @returns Redbook Image URL
 */
export const getRedbookImageURL = (vehicleTypeCode, photoId) => {
  if (isEmpty(photoId)) {
    return 'https://via.placeholder.com/405x239'
  }
  switch (vehicleTypeCode) {
    case 'PS':
    case 'LC':
    case 'SV':
      return `https://liveimages.redbook.com.au/redbook/car/spec/${photoId}.jpg`
    case 'HC':
      return `https://liveimages.redbook.com.au/redbook/construction/spec/${photoId}.jpg`
    default:
      return 'https://via.placeholder.com/405x239'
  }
}

/**
 * Function to get image data by size.
 *
 * @param {String} url      Image URL.
 * @param {String} sizeName Size name.
 *
 * @return {Array}
 */

export const getCloudName = (url) => {
  try {
    const urlObject = new URL(url)
    return (
      urlObject?.pathname?.match(/(driveau|driveau-staging|drive-mustang)/gi)?.[1] ??
      DEFAULT_CLOUDINARY_CLOUD_NAME
    )
  } catch (ex) {
    logger.warn(`[Image warning - received an invalid URL] ${url}`)
  }
  return DEFAULT_CLOUDINARY_CLOUD_NAME
}

export const getImageBySize = (
  url,
  sizeName,
  cloudinaryConfig,
  isDefault,
  tailPipeExternalId = null
) => {
  if (!url || !sizeName) {
    return {
      src: url,
      srcSet: '',
      sizes: ''
    }
  }
  // Replace double URL prefixes, this looks like a bug in content migration | cloudinary plugin
  try {
    url = url.replace(
      /https:\/\/(staging|dev|prod)-boot.drivemustang.com.au\/wp-content\/uploads\//,
      ''
    )
  } catch (error) {
    logger.error(`Null URL passed to getImageBySize - ${JSON.stringify(error.stack)}`)
    throw error
  }

  const hasCloudinaryConfig =
    cloudinaryConfig && !isEmpty(cloudinaryConfig.publicId) && !isEmpty(cloudinaryConfig.cloudName)

  const cloudName = hasCloudinaryConfig ? cloudinaryConfig.cloudName : getCloudName(url)
  let fileName = hasCloudinaryConfig ? cloudinaryConfig.publicId : getImgFileNameFromUrl(url)
  const deliveryType =
    hasCloudinaryConfig && !isEmpty(cloudinaryConfig.deliveryType)
      ? cloudinaryConfig.deliveryType
      : 'upload'

  let newSrc = '' // This will be the desktop image url.

  // We should only fallback to using folder name if cloudinary config's are missing
  if (
    !hasCloudinaryConfig &&
    !isDefault &&
    IMAGES_FOLDERS?.[sizeName] &&
    fileName.indexOf(IMAGES_FOLDERS[sizeName]) < 0
  ) {
    fileName = `${IMAGES_FOLDERS?.[sizeName]}${fileName}`
  }
  if (!hasCloudinaryConfig && !isDefault && !IMAGES_FOLDERS?.[sizeName]) {
    // fallback to default folder size name
    let folderName = IMAGES_FOLDERS.DEFAULT_FOLDER_PATH

    if (tailPipeExternalId) {
      fileName = tailPipeExternalId // includes foldername and fileName already
    } else {
      fileName = `${folderName}${fileName}`
    }
  }

  // Use default image if isDefault is set
  if (isDefault) {
    fileName = `${IMAGES_FOLDERS?.DEFAULT_IMAGE}${fileName}`
  }

  let srcSet = []
  if (IMAGES_BREAKPOINT?.[sizeName] && isArray(IMAGES_BREAKPOINT?.[sizeName])) {
    srcSet = IMAGES_BREAKPOINT?.[sizeName].map((size) => {
      let config = {
        deliveryType: deliveryType,
        /* use prod cloudinary if image is showing from redbook source
         * as driveau-staging and driveau-mustang images are never synched
         */
        cloudName: tailPipeExternalId ? 'driveau' : cloudName,
        transformation: size,
        publicId: fileName
      }

      let imgUrl = makeUrl(config)
      if (!newSrc && size?.useForSrc) {
        newSrc = imgUrl
      }

      /** Exclude source if useForSrcSet is set to false */
      if (size?.useForSrcset !== undefined && size?.useForSrcset === false) {
        return
      }

      imgUrl = `${imgUrl} ${size?.imgBreakpoint}w`
      return imgUrl
    })
  }

  return {
    src: newSrc,
    srcSet: srcSet.filter((src) => src).join(','),
    sizes: IMAGES_SIZES?.[sizeName]
  }
}

/**
 * Function to get fileName from url.
 *
 * @param {String} url Get filename from url.
 *
 * @return {String}
 */
export const getImgFileNameFromUrl = (url) => {
  if (!url) {
    return ''
  }
  return url.split('/').pop()
}

/**
 * Function to get cover image block url.
 *
 * @param {String} url Image URL.
 *
 * @return {String}
 */
export const getCoverImageUrl = (url) => {
  const fileName = getImgFileNameFromUrl(url)

  const cloudinaryUrl = makeUrl({
    publicId: fileName,
    cloudName: CLOUDINARY_CLOUD_NAME,
    transformation: {
      width: 1200,
      gravity: 'auto',
      crop: 'fill',
      quality: 'auto:best',
      fetchFormat: 'auto'
    }
  })

  return cloudinaryUrl || url
}

/**
 * Function to get publicId from url.
 *
 * @param {String} url Image URL.
 *
 * @return {String}
 */
export const getPublicId = (srcUrl) => {
  // Split the URL by '/'
  const parts = srcUrl?.split('/')
  // Find the index of 'vehicles'
  const index = parts?.indexOf('vehicles')
  // Join the parts from 'vehicles' onwards
  return parts?.slice(index)?.join('/')
}
