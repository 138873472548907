import { gql } from '@apollo/client'
import { CarsForSaleOptionFragment } from '../../fragments/cars-for-sale/index'

/** Get Cars for sale listing counts using where and groupby query  */
export const GET_LISTING_MAKES_AVAILABLE = gql`
  query GET_LISTING_MAKES_AVAILABLE($where: WhereOptionsDealerListing) {
    availableMakes: DealerListingsCountBy(where: $where, group: makeDescription) {
     ${CarsForSaleOptionFragment}
  }
}
`

/** Get Cars for sale listing counts using where and groupby query  */
export const GET_LISTING_MAKES_ALL = gql`
  query GET_LISTING_MAKES_ALL {
    makes: DealerListingsCountBy(group: makeDescription) {
     ${CarsForSaleOptionFragment}
    }
  }
`

/**
 * @description simple query to get make description by slug
 */
export const GET_TP_MAKE_DESCRIPTION = gql`
  query GET_TP_MAKE_DESCRIPTION($slug: String) {
    make: Make(where: { slug: $slug }) {
      id
      makeableUuid
      slug
      description
    }
  }
`

/** Get Cars for sale available makes options for  ghost listings.  */
export const GET_GHOST_LISTING_MAKES_AVAILABLE = gql`
  query GET_GHOST_LISTING_MAKES_AVAILABLE($where: WhereOptionsGhostListing) {
    availableGhostListingMakes: GhostListingsCountBy(where: $where,group: makeDescription) {
     ${CarsForSaleOptionFragment}
    }
  }
`
/**
 * Gets both dealer listing and ghost listing options
 */
export const GET_ALL_LISTING_MAKES_AVAILABLE = gql`
  query GET_ALL_LISTING_MAKES_AVAILABLE($dealerListingWhere: WhereOptionsDealerListing, $ghostListingWhere: WhereOptionsGhostListing) {
    availableMakes: DealerListingsCountBy(where: $dealerListingWhere, group: makeDescription) {
     ${CarsForSaleOptionFragment}
  }
    availableGhostListingMakes: GhostListingsCountBy(where: $ghostListingWhere,group: makeDescription) {
     ${CarsForSaleOptionFragment}
    }
  }
`
