import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Link, { linkDefaultProps } from '../../common/link'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import useWindowWidth from '@grille/utils/hooks/use-window-width'
import { MEDIUM_WINDOW_WIDTH } from '@grille/constants'

import { normalizeHeaderMenuData } from '../../../utils/functions/menu'
import styles from './navbarItem.module.scss'
import { PageContext } from '../../../utils/contexts/pageContext'
import { Color } from '../../../constants/colors'
import { Panels } from '../types'
import { ExpandMoreIcon } from '../../icons'

/**
 * @description only for head menu. latest design has no dropdown, but dropdown is preserved, most code is kept so it is backward compatible.
 *  - when menuItem has nodes for submenu => link with arrow and dropdown panel
 *  - when menuItem has no nodes for submenu => link only
 * @returns
 */
const NavbarItems: FunctionComponent<NavbarItemsProp> = ({ items }) => {
  const { pageStore } = useContext<{ pageStore: PageStore }>(PageContext)
  const { windowWidth }: { windowWidth: number; windowSize: string } = useWindowWidth()
  const memoizedItems = useMemo<MenuItem[]>(() => normalizeHeaderMenuData(items), [items])
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const renderLink: FunctionComponent<Menu> = (menu: Menu) => {
    const hasSubmenu: boolean = !isEmpty(menu.childItems)
    return (
      <div
        className={cx(styles['drive-navbar__items__wrapper'], {
          'triangle-bottom': hasSubmenu
        })}
      >
        <Link {...linkDefaultProps} href={menu?.path}>
          <a role='menuitem' className={cx(styles['drive-navbar__items__link'])}>
            {menu.label}
          </a>
        </Link>
        {hasSubmenu && (
          <span className={cx(styles['drive-navbar__items__icon-wrapper'])}>
            <ExpandMoreIcon height='24' width='24' color={Color.blueNormal} />
          </span>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (windowWidth <= MEDIUM_WINDOW_WIDTH) {
      setIsMobileDevice(true)
    }
  }, [windowWidth])

  if (isMobileDevice) {
    {
      /* default windows width is LARGE_WINDOW_WIDTH when window is undefined, MEDIUM_WINDOW_WIDTH used for seo. */
    }
    return null
  }

  return (
    <div className={cx(styles['drive-navbar__items__container'])}>
      <div className={cx(styles['drive-navbar__items__menus'])} role='menubar'>
        {memoizedItems.length &&
          memoizedItems.map((menu, index) => {
            return (
              <div
                aria-haspopup={!!menu.childItems}
                key={index}
                className={cx(styles['drive-navbar__items__menu-wrapper'], {
                  'navbar-item': menu.childItems && !(pageStore?.openedPanel === Panels.searchPanel)
                })}
              >
                <div
                  className={cx(styles['drive-navbar__items__menu'], {
                    [cx(styles['drive-navbar__items__mobile-show-all'])]:
                      menu?.cssClasses?.[0] === 'mobile-show-all'
                  })}
                >
                  {menu?.path && renderLink(menu, index)}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default NavbarItems

type MenuItem = {
  [key: string]: any
}

type NavbarItemsProp = {
  items: MenuItem[]
}

type PageStore = {
  [key: string]: any
}

type Menu = {
  [key: string]: any
}
