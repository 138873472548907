import { RetryLink } from '@apollo/client/link/retry'
import logger from '../utils/logger'

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => {
      if (error?.statusCode && error.statusCode === 400) {
        return false
      }
      logger.info(
        `[GraphQL]: retryLink.try: Operation [${
          _operation.operationName
        }] on error [${error.toString()}]`
      )
      return !!error
    }
  }
})
