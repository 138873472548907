import { ReactElement } from 'react'
import MakeModelSearch from './make-model-search'
import { Make } from './../../types/findACar'
import cx from 'classnames'
import styles from './findACar.module.scss'

/**
 * React fragment, a radio input follow by a search commponent, for SSR support
 * @param  id input id, also used as id prefix for MakeModelSearch's inner components
 * @param  inputRadioName input name which for linking label
 * @param  allMakes @optional all makes being passed to search component
 * @param  popularMakes @optional popular makes being passed to search coponent
 * @param  searchType whether is for showroom or cfs listing search
 * @param  isSSR whether is for server side rendering
 * @returns {ReactElement}
 */
const InputAndSearchFragment = (props: {
  isSSR?: boolean
  id: string
  inputRadioName: string
  allMakes?: Make[]
  popularMakes?: Make[]
  searchType: 'showroom' | 'car-for-sale'
}): ReactElement => {
  return (
    <>
      <input
        type='radio'
        name={props.inputRadioName}
        id={props.id}
        className={cx(styles['d-find-a-car__search-filter-radio'])}
      ></input>
      <MakeModelSearch
        customClass={cx(styles['d-find-a-car__search-filter'])}
        makes={{
          allMakes: props.allMakes ?? [],
          popularMakes: props?.popularMakes ?? []
        }}
        searchType={props.searchType}
        isSSR={props.isSSR}
        idPrefix={props.id}
      />
    </>
  )
}
export default InputAndSearchFragment
