// https://securepubads.g.doubleclick.net/gampad/adx?iu=/5167826/Drive.com.au&sz=1x5&t=pos%3D1%26env%3Dstaging

import fetch from 'cross-fetch'
import isEmpty from 'lodash/isEmpty'
import { getAdPath } from '../../../utils/functions/gtm'
import logger from '../../../utils/logger'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
const BASE_AD_URI = 'https://securepubads.g.doubleclick.net/gampad/adx?iu='

const TAGLESS_AD_API_PATH = getRuntimeEnv('NEXT_PUBLIC_TAGLESS_AD_API_URL')

export function createAdUrl(id, gamData) {
  // Slot Targeting Key Values
  const adConfig = gamData.slots[id]
  const targeting = []
  ;(adConfig.targeting || []).forEach((kvPair) => {
    targeting.push(`${kvPair[0]}=${kvPair[1]}`)
  })

  // GAM global targeting key values
  const adTargeting = gamData.adTargeting ?? {}
  const { gamAdUnitPath } = gamData
  Object.keys(adTargeting).forEach((key) => {
    !isEmpty(adTargeting[key]) && targeting.push(`${key.toLowerCase()}=${adTargeting[key]}`)
  })

  const siteSectionPath = getAdPath(adTargeting)
  const pagePath = window.location.pathname + window.location.hash

  if (!gamData._correlator) {
    gamData._correlator = {}
  }

  if (!gamData._correlator[pagePath]) {
    gamData._correlator[pagePath] = (Math.random() * 1e7 + '').split('.')[0]
  }

  const rand = gamData._correlator[pagePath]
  const adUrl = `${BASE_AD_URI}${gamAdUnitPath}${siteSectionPath}&sz=${adConfig.dimension.join(
    'x'
  )}&d_imp=1&d_imp_hdr=1&t=${encodeURIComponent(targeting.join('&'))}&c=${rand}`
  return adUrl
}

async function AdTagless({ id, endpoint }) {
  return new Promise((resolve) => {
    if (!window) {
      resolve({ status: 'noad', data: {}, message: 'NotInClientSideContext' })
      return
    }

    const host = endpoint || TAGLESS_AD_API_PATH
    if (!host) {
      logger.warn(`ads.tagless - No endpoint configured ${id}`)
      resolve({ status: 'noad', data: {}, message: 'EndpointNotConfigured' })
      return
    }

    const { gamData } = window
    if (!gamData || !gamData.slots || !gamData.slots[id]) {
      logger.warn(`ads.tagless - an invalid slot id was passed or no slot config for ${id}`)
      resolve({ status: 'noad', data: {}, message: 'NoGamConfig' })
      return
    }

    const adUrl = createAdUrl(id, gamData)
    const adHeaders = { 'content-type': 'application/json', mode: 'cors' }

    logger.debug(`adTagless.req [${id}] host=[${host}] gam=[${adUrl}]`)

    fetch(host, { headers: adHeaders, body: JSON.stringify({ adUrl: adUrl }), method: 'POST' })
      .then(async (response) => {
        logger.debug(`adTagless.res [${id}] - status=[${response.status}] ok=[${response.ok}]`)
        if (response.status < 400 && response.ok) {
          const adResponse = await response.json()
          logger.debug(`adTagless.data [${id}]`, { status: 'ok', data: adResponse, path: adUrl })
          resolve({ status: 'ok', data: adResponse, path: adUrl })
        } else {
          resolve({ status: 'noad', data: {}, path: adUrl })
        }
      })
      .catch((e) => {
        logger.debug('GamServerError', e)
        resolve({ status: 'noad', data: {}, path: adUrl })
      })
  })
}

export default AdTagless
