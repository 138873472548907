import isEmpty from 'lodash/isEmpty'
import { Make, Option } from '../../../types/findACar'

export const getLink = (
  searchType: 'showroom' | 'car-for-sale',
  make: Option | null,
  model: Option | null
) => {
  if (searchType === 'showroom') {
    const makePath: string = make?.slug ? `${make?.slug}/` : ''
    const modelPath: string = model?.slug ? `${model?.slug}/` : ''
    return `/showrooms/${makePath}${modelPath}`
  } else {
    const makePath: string = make?.slug ? `${make.slug}/` : ''
    const modelPath: string = isEmpty(makePath) ? '' : model?.slug ? `${model.slug}/` : ''
    if (makePath !== '') {
      return `/cars-for-sale/search/all/all/${makePath}${modelPath}`
    } else {
      return '/cars-for-sale/search/'
    }
  }
}

export const getMakeOptions = (makes: Make[]): (Option | undefined)[] | null => {
  return makes
    ? makes?.map((make) => {
        if (make?.label && make?.value) {
          return { label: make.label, value: make.value, slug: make.slug }
        }
      })
    : null
}
