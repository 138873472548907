export const CarsForSaleListingFragment = `
results {
  listingId: id
  type: stockType
  priceEgc
  priceDa: priceIgc
  variant: description
  createdAt
  image: mainImage {
    url
    publicId
    cloudName
  }
  makeDescription
  familyDescription
  redbookVehicleKey
  redbook: RedbookVehicle {
    id
    vehicleKey
    year: yearGroup
  }
  Dealer {
    id
    state
    suburb
    allowEgc
  }
}
`

export const CarsForSaleOptionFragment = `
  by
  count
  options: values {
    name: field
    slug
    count
  }
`
