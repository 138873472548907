import { ApolloLink } from '@apollo/client'
import logger from '../utils/logger'

/**
 * Middleware operation for CF Access
 */

export default function accessMiddleware(
  linkName,
  serverUri,
  options = {
    setAuthHeaders: false
  }
) {
  return new ApolloLink((operation, forward) => {
    const queryName = operation?.operationName || 'UNNAMED_QUERY'

    logger.debug(
      `${linkName} Query=[${queryName}] Host=[${serverUri}] Operation=[${JSON.stringify(
        operation?.variables || {}
      )}]`
    )

    if (process.env.NEXT_CF_ACCESS_CLIENT_ID && process.env.NEXT_CF_ACCESS_CLIENT_SECRET) {
      operation.setContext(({ headers }) => {
        let authHeaders = {}
        if (options.setAuthHeaders) {
          authHeaders = headers
        }
        return {
          headers: {
            ...authHeaders,
            'CF-Access-Client-Id': `${process.env.NEXT_CF_ACCESS_CLIENT_ID}`,
            'CF-Access-Client-Secret': `${process.env.NEXT_CF_ACCESS_CLIENT_SECRET}`
          }
        }
      })
    }
    return forward(operation)
  })
}
