export const INREADS_POS2 = 0
export const INREADS_POS4 = 2
export const INREADS_POS6 = 4
export const POS3_AD_INDEX = 3
export const POS5_AD_INDEX = 5
export const IAS_PUB_ID = '931687'

export const NEWS_CAR_ADVICE_OPINION_ADS = [
  'gam_inreads_pos2',
  'gam_texttransact_pos1',
  'gam_inreads_pos3',
  'gam_texttransact_pos2',
  'gam_inreads_pos4',
  'gam_inreads_pos5',
  'gam_inreads_pos6',
  'gam_inreads_pos7',
  'gam_inreads_pos8',
  'gam_inreads_pos9',
  'gam_inreads_pos10',
  'gam_inreads_pos11',
  'gam_inreads_pos12'
]

//guide landing page
export const GUIDES_ADS_MOBILE = [
  'gam_inreads_pos2',
  'gam_inreads_pos3',
  'gam_inreads_pos4',
  'gam_inreads_pos5',
  'gam_inreads_pos6',
  'gam_inreads_pos7',
  'gam_inreads_pos8',
  'gam_inreads_pos9',
  'gam_inreads_pos10',
  'gam_inreads_pos11',
  'gam_inreads_pos12'
]

/**
 * guide landing page desktop: roofrack pos1 is rendered via blocks, as inline ad, not from layout.
 * layout roofrack ad should not be rendered in dom if this is used
 **/
export const GUIDES_ADS_DESKTOP = [
  'gam_roofrack_pos1',
  'gam_inreads_pos2',
  'gam_inreads_pos3',
  'gam_inreads_pos4',
  'gam_inreads_pos5',
  'gam_inreads_pos6',
  'gam_inreads_pos7',
  'gam_inreads_pos8',
  'gam_inreads_pos9',
  'gam_inreads_pos10',
  'gam_inreads_pos11',
  'gam_inreads_pos12'
]

export const GUIDES_SINGLE_ADS = [
  'gam_inreads_pos2',
  'gam_inreads_pos3',
  'gam_inreads_pos4',
  'gam_inreads_pos5',
  'gam_inreads_pos6',
  'gam_inreads_pos7',
  'gam_inreads_pos8',
  'gam_inreads_pos9',
  'gam_inreads_pos10',
  'gam_inreads_pos11',
  'gam_inreads_pos12'
]

export const CFS_LANDING_ADS = [
  'gam_inreads_CFS_pos3',
  'gam_inreads_CFS_pos6',
  'gam_inreads_CFS_pos7'
]
