import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { getImageBySize, getImageSize } from '../../../utils/functions/images'
import {
  CAR_FOR_SALE_DEFAULT_IMG_URL,
  DEFAULT_CAR_SILHOUETTE_URL,
  DEFAULT_HERO_IMG_URL,
  DEFAULT_IMG_URL
} from '../../../constants/media'
import logger from '../../../utils/logger'
import styles from './image.module.scss'
import { Cloudinary } from '@grille/types/image'
import { FunctionComponent, ImgHTMLAttributes } from 'react'

/**
 * Image Component.
 * - sizename depends on cloudinary object, and should not be passed if cloudinary object is empty.
 *
 * @TODO To check later for next/image component and remove the placeholder and react lazy if needed.
 */
const Image: FunctionComponent<TImageProp> = ({
  cloudinary = {},
  height,
  width,
  size,
  showSizes = true,
  isExternal = false,
  onLoad,
  tailPipeExternalId,
  isDefault = false,
  fetchpriority,
  draggable,
  altText = '',
  caption = '',
  title = '',
  mediaDetails = {
    height: '',
    width: ''
  },
  sourceUrl = '',
  srcSet = '',
  className = '',
  postTitle = '',
  imageBg = 'bg-gray-200',
  showDefault = true,
  sizeName = '',
  defaultImgType = 'common',
  loading = 'lazy',
  isCarsForSaleDefaultImage = false,
  onClick,
  dataTestId
}: TImageProp) => {
  !cloudinary && logger.debug(`${sourceUrl} missing cloudinary object`)

  // strip alt and caption of html tags
  const parsedAltText = altText ? altText.replace(/(<([^>]+)>)/gi, '') : ''
  const parsedCaption = caption ? caption.replace(/(<([^>]+)>)/gi, '') : ''

  if (!sourceUrl && !showDefault) {
    return null
  } else if (!sourceUrl && showDefault) {
    let defaultImgSrc = DEFAULT_IMG_URL

    if (defaultImgType === 'hero') {
      defaultImgSrc = DEFAULT_HERO_IMG_URL
    }
    if (defaultImgType == 'silhouette') {
      defaultImgSrc = DEFAULT_CAR_SILHOUETTE_URL
    }

    let defaultPrimaryImgData:
      | {
          src?: any
          srcSet?: string
          sizes?: string
        }
      | {
          src?: string
          srcSet?: any
          sizes?: any
        } = {}
    if (isCarsForSaleDefaultImage) {
      defaultImgSrc = CAR_FOR_SALE_DEFAULT_IMG_URL
      defaultPrimaryImgData = getImageBySize(defaultImgSrc, sizeName, cloudinary, true)
    } else if (sizeName) {
      defaultPrimaryImgData = getImageBySize(defaultImgSrc, sizeName, cloudinary, true)
    } else {
      /** Use default size name if size name not provided */
      defaultPrimaryImgData = getImageBySize(defaultImgSrc, 'THEME_IMAGE', cloudinary)
    }
    return (
      <img
        onLoad={onLoad}
        loading={loading as any}
        sizes={defaultPrimaryImgData?.sizes ?? ''}
        srcSet={defaultPrimaryImgData?.srcSet}
        src={defaultPrimaryImgData?.src ?? defaultImgSrc}
        width={width || 'auto'}
        height={height || 'auto'}
        alt={parsedAltText || title || parsedCaption || postTitle || 'Drive Image'}
        className={cx(styles['drive-image'], className, imageBg)}
        fetchPriority={fetchpriority ?? loading === 'eager' ? 'high' : undefined}
        draggable={draggable}
        data-testid={dataTestId}
      />
    )
  }

  let imageData: Record<string, any> = {}

  if (!isExternal && sizeName && sourceUrl) {
    imageData = getImageBySize(
      sourceUrl,
      sizeName,
      cloudinary,
      isDefault,
      tailPipeExternalId as any
    )
  }

  let imageSize: Record<string, any> = getImageSize(mediaDetails?.sizes, size as any)
  // If large image size does not exist, fallback to medium_large size
  imageSize = isEmpty(imageSize) ? getImageSize(mediaDetails?.sizes, 'medium_thumbnail') : imageSize

  /**
   * WARNING
   *
   * The order of the following img attributes is very important to avoid unintended browser behaviours:
   * 1. loading
   * 2. sizes
   * 3. srcset
   * 4. src
   *
   * @see https://driveau.atlassian.net/browse/CC-623
   */
  return (
    <img
      loading={loading ?? 'lazy'}
      {...(showSizes && !isEmpty(imageData?.srcSet)
        ? {
            sizes: imageData?.sizes || 'sizes=(max-width: 620px) 100vw, 1280px',
            srcSet: imageData?.srcSet || srcSet
          }
        : {})}
      src={imageData?.src || sourceUrl}
      alt={parsedAltText || parsedCaption || postTitle || title || 'Drive'}
      height={!isEmpty(imageSize?.height) ? imageSize?.height : height || 'auto'}
      width={!isEmpty(imageSize?.width) ? imageSize?.width : width || 'auto'}
      fetchPriority={loading === 'eager' ? 'high' : fetchpriority ?? undefined}
      onLoad={onLoad}
      onClick={onClick}
      className={cx(styles['drive-image'], className, imageBg)}
      data-testid={dataTestId}
    />
  )
}

export type TImageProp = {
  altText?: string | null
  isExternal?: boolean
  showSizes?: boolean
  caption?: string | null
  isDefault?: boolean
  className?: string | null
  title?: string | null
  mediaDetails?: Record<string, any>
  sourceUrl?: string | null
  srcSet?: string | null
  cloudinary?: Cloudinary | { [key: string]: any } | null | {}
  width?: string | number
  height?: string | number
  postTitle?: string | null
  imageBg?: string | null
  sizeName?: string | null
  defaultImgType?: string | 'common' | 'hero' | 'silhouette' | null
  showDefault?: boolean
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading']
  isCarsForSaleDefaultImage?: boolean
  placeholder?: Record<string, any> | null
  size?: string | undefined
  sizes?: string | null
  alt?: string | null
  tailPipeExternalId?: string | null
  fetchpriority?: ImgHTMLAttributes<HTMLImageElement>['fetchPriority']
  onLoad?: () => void
  draggable?: boolean
  onClick?: () => void
  dataTestId?: string
}

export default Image
