import cx from 'classnames'
import styles from './find-a-car-button.module.scss'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import Icon from '../../common/icon'
import { Color } from '../../../constants/colors'
import FindACar from '../../find-a-car'
import { Panels } from '../types'
import { PageContext, useUpdatePageCtx } from '../../../utils/contexts/pageContext'

const FindACarButton: FunctionComponent<FindACarButtonProps> = () => {
  const checkboxTrackId: string = 'find-a-char-model-checkbox'
  const [isMobile, setIsMobile] = useState(false)
  const [isBrowser, setIsBrowser] = useState<boolean>(false)
  const { pageStore } = useContext(PageContext)
  const { updateStore } = useUpdatePageCtx()
  const showSearch: boolean = pageStore?.openedPanel === Panels.searchPanel

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsBrowser(true)
      if (window.innerWidth < 768) {
        setIsMobile(true)
      }
    }
  }, [])

  return (
    <>
      <div className={cx(styles['drive-find-a-car-button'])}>
        <button
          onClick={() => {
            updateStore('openedPanel', Panels.searchPanel)
          }}
          type='button'
          className={cx(styles['drive-find-a-car-button__button'])}
          aria-label='toggle find-a-car model'
        >
          <label
            htmlFor='find-a-char-model-checkbox'
            className={cx(styles['drive-find-a-car-button__button__label'], {
              [styles['drive-find-a-car-button__button__label--modal-closed']]: !showSearch
            })}
          >
            Find • Buy • Sell
          </label>
        </button>

        {!isBrowser || showSearch ? ( //always render for srr, use state for reseting form data for browser
          <div style={{ opacity: isBrowser ? 1 : 0 }}>
            <input
              type='checkbox'
              name={checkboxTrackId}
              id={checkboxTrackId}
              defaultChecked={false}
            ></input>
            <div className={cx(styles['drive-find-a-car-button__modal'])}>
              <div className={cx(styles['drive-find-a-car-button__modal__content'])}>
                <div className={styles['drive-find-a-car-button__modal__inner-content']}>
                  <div
                    className={cx(styles['drive-find-a-car-button__modal__find-a-car-container'])}
                  >
                    {!isMobile ? (
                      <div
                        className={cx(styles['drive-find-a-car-button__modal__close-btn-wrapper'])}
                      >
                        <label
                          htmlFor={checkboxTrackId}
                          onClick={() => {
                            updateStore('openedPanel', null)
                          }}
                        >
                          <Icon
                            name={'CloseIcon'}
                            width='1.5rem'
                            height='1.5rem'
                            color={Color.white}
                            className={cx(styles['drive-find-a-car-button__modal__close-btn'])}
                          />
                        </label>
                      </div>
                    ) : null}
                    {/* Find a car from header menu should support ssr */}
                    <FindACar inputIdPrefix='fac-modal' isMobile={isMobile} isSSR={!isBrowser} />

                    {isMobile ? (
                      <label
                        htmlFor={checkboxTrackId}
                        className={cx(styles['drive-find-a-car-button__modal__close-btn-wrapper'])}
                        onClick={() => {
                          updateStore('openedPanel', null)
                        }}
                      >
                        <Icon
                          name={'CloseIcon'}
                          width='1.5rem'
                          height='1.5rem'
                          color={Color.white}
                          className={cx(styles['drive-find-a-car-button__modal__close-btn'])}
                        />
                      </label>
                    ) : null}
                  </div>
                </div>
                {!isMobile ? (
                  <div
                    className={cx(styles['drive-find-a-car-button__modal__close-area'])}
                    onClick={() => {
                      updateStore('openedPanel', null)
                    }}
                  ></div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showSearch ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `
              html, body { max-height: 100%; overflow:hidden; }
          `
          }}
        />
      ) : null}
    </>
  )
}

export default FindACarButton

type FindACarButtonProps = {}
