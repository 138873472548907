import cx from 'classnames'
import { FunctionComponent } from 'react'
import { ChevronDown, ChevronUp } from '../../../icons'
import { Color } from '../../../../constants/colors'

/**
 * Arrows in React Select
 * @type {Specific Component}
 * @param isDisabled wether dropdown is disabled
 * @customClassNames custom class names for wrapper
 * @returns FunctionComponent
 */
const DropdownIndicatorArrow: FunctionComponent<Props> = ({
  isDisabled = false,
  color = Color.black,
  customClassNames = '',
  height = '12',
  width
}) => {
  const chevronColor: Color = isDisabled ? Color.gray400 : color
  return (
    <span className={cx(customClassNames)}>
      <ChevronUp height={height} width={width} color={chevronColor} />
      <ChevronDown height={height} width={width} color={chevronColor} />
    </span>
  )
}

type Props = {
  isDisabled: boolean
  color?: Color
  classNamePrefix?: string
  customClassNames?: string
  height?: string
  width?: string
}
export default DropdownIndicatorArrow
