import logger from '../../logger'
const parseJSONSafe = (json: string | any, keyname: string, defaultResponse: any = {}) => {
  if (typeof json === 'string') {
    try {
      return JSON.parse(json)
    } catch (e) {
      logger.warn(`parseJSONSafe - ERROR  - failed to parse JSON key: [${keyname}] data: ${json}`)
      return defaultResponse
    }
  }
  return json
}

export default parseJSONSafe
