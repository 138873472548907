import isEmpty from 'lodash/isEmpty'
import { getRuntimeEnv } from './functions/get-runtime-env'

const DEFAULT_CLOUDNAME = getRuntimeEnv('NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME')
const IMAGES_HOSTNAME: string =
  (getRuntimeEnv('NEXT_PUBLIC_IMAGE_HOSTNAME') as string) || 'media.drive.com.au'

import {
  CloudinaryTransformObject,
  getImageUrlCloudinaryTransforms
} from '@caradvice/drive-imagine'

export interface CloudinaryImage {
  publicId: string
  deliveryType?: 'upload' | 'private' | 'youtube'
  cloudName?: string
  transformation?: CloudinaryTransformObject
  transformationString?: string
}

export function makeUrl(req: CloudinaryImage) {
  const { publicId: _publicId, deliveryType, cloudName, transformation } = req
  const publicId = _publicId?.split('.')[0]
  const _cloudName: string = (
    isEmpty(cloudName) || cloudName === 'image' ? DEFAULT_CLOUDNAME : cloudName
  ) as string

  return getImageUrlCloudinaryTransforms(IMAGES_HOSTNAME, {
    assetId: publicId,
    deliveryType,
    cloudName: _cloudName,
    transforms: transformation
  })
}
