import { gql } from '@apollo/client'

export const GET_TP_ALL_CURRENT_RELEASE_MAKES = gql`
  query GET_TP_ALL_CURRENT_RELEASE_MAKES {
    makes: MakesAll(where: { currentRelease: { eq: T } }) {
      id
      slug
      makeableId
      label: description
      value: makeableUuid
      numFamilies(where: { currentRelease: { eq: T } })
    }
  }
`
