import { JSX } from 'react'
import { components, GroupBase, InputProps, OptionProps } from 'react-select'
/**
 * @description
 * react-select has issue of generating different attributes for components in ssr and client side.
 * this object contains amended components which resolved server/client side unmatched attribute issue.
 * @usage
 * include in components prop in Select from react-select
 * eg. components:{Input:amendedComponents.Input, ...otherCustomComponents}
 * it is not recommended to use components:{...amendedComponents, ...otherCustomComponents} due to the followings:
 * - only certain select default components are being used in select depending on other props, therefore always override specifically.
 * - the content of amendedComponents may also change.
 */
export const amendedComponents = {
  Input: (props: JSX.IntrinsicAttributes & InputProps<any, boolean, GroupBase<any>>) => {
    //remove aria-activedescendant to match client and server side
    return <components.Input {...props} aria-activedescendant={undefined} />
  },
  Option: (props: JSX.IntrinsicAttributes & OptionProps<any, boolean, GroupBase<any>>) => {
    // this is to resolve hydration errors due to extra properties being shown in server side
    delete props.innerProps?.['aria-selected']
    delete props.innerProps?.['aria-activedescendant']
    return <components.Option {...props} />
  }
}
