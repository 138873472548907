import isEmpty from 'lodash/isEmpty'
import AdTagless from '../../../components/common/ad-units/adTagless'
import {
  GET_RELATED_CAR_ADVICES_AD_UNIT,
  GET_RELATED_NEWS_AD_UNIT,
  GET_RELATED_REVIEWS_AD_UNIT,
  GET_SHOWROOMS_MODEL_SPONSORED
} from '../../../queries/ads/related-content'

import client from '../../../apollo/client'
import logger from '../../../utils/logger'

const fetchDataFromWP = async (rawAdData, query, variables = {}) => {
  if (process.browser) {
    try {
      const { data, error } = await client.query({
        query: query,
        variables: {
          ...variables
        }
      })

      if (!error && data) {
        if (data?.post && typeof data.post === 'object') {
          const postData = {
            ...data.post,
            clickthroughUrl: rawAdData?.clickthroughUrl,
            impressionTrackingUrls: rawAdData?.impressionTrackingUrls
          }
          return {
            id: rawAdData.requestId,
            data: postData,
            url: rawAdData.clickthroughUrl,
            isExternalURL: !isEmpty(rawAdData.clickthroughUrl),
            impressionTrackingUrls: rawAdData.impressionTrackingUrls,
            rawAdData: rawAdData
          }
        } else {
          return null
        }
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }
}

const getFetchType = async (rawAdData) => {
  if (process.browser) {
    let data = null
    const { postIdentifier, postType } = rawAdData
    switch (postType) {
      case 'ShowroomsModel':
        data = await fetchDataFromWP(rawAdData, GET_SHOWROOMS_MODEL_SPONSORED, {
          uri: postIdentifier
        })
        break
      case 'News':
        data = await fetchDataFromWP(rawAdData, GET_RELATED_NEWS_AD_UNIT, {
          uri: postIdentifier
        })
        break
      case 'Review':
        data = await fetchDataFromWP(rawAdData, GET_RELATED_REVIEWS_AD_UNIT, {
          uri: postIdentifier
        })
        break
      case 'CarAdvice':
        data = await fetchDataFromWP(rawAdData, GET_RELATED_CAR_ADVICES_AD_UNIT, {
          uri: postIdentifier
        })
        break
      default:
        break
    }

    return data
  }
}

const getAdUnitWPType = async (rawAdData = {}) => {
  if (Object.keys(rawAdData).length === 0 || !('postType' in rawAdData)) return rawAdData // bail early if nothing in rawAdData or no postType is found
  return await getFetchType(rawAdData)
}

const getAdUnitImageType = (rawAdData = {}) => {
  if (process.browser) {
    if (!Object.keys(rawAdData).length) return rawAdData // bail early if nothing in rawAdData
    const title = rawAdData.caption

    return {
      id: rawAdData.requestId,
      label: title,
      title: title,
      image: {
        isExternal: true,
        title: title,
        altText: title,
        sourceUrl: rawAdData.images?.desktop
      },
      mobileImage: rawAdData.images?.mobile,
      url: rawAdData.clickthroughUrl,
      isExternalURL: true,
      impressionTrackingUrls: rawAdData.impressionTrackingUrls
    }
  }
}

export const getAdUnit = async (adData) => {
  try {
    const { data } = await AdTagless({ id: adData.slot })
    const checkPostTypeCasing = data?.postType?.toLowerCase() ?? ''

    if (checkPostTypeCasing === 'image') {
      const adUnitImageType = getAdUnitImageType(data)
      if (!isEmpty(adUnitImageType)) {
        return adUnitImageType
      }
    } else {
      const adUnitWpType = await getAdUnitWPType(data)
      return adUnitWpType
    }
  } catch (error) {
    logger.error(error)
  }
}

export const getFeaturedCarAdUnit = async () => {
  if (process.browser) {
    const slotNames = [
      'gam_featblockcars_pos1',
      'gam_featblockcars_pos2',
      'gam_featblockcars_pos3',
      'gam_featblockcars_pos4'
    ]
    const featuredAdUnits = []
    for (let index = 0; index < slotNames.length; index++) {
      const adUnit = await getAdUnit(slotNames[index])
      if (adUnit?.data) {
        featuredAdUnits.push(adUnit)
      }
    }
    return featuredAdUnits
  }
}
