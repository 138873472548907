import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, split, ApolloLink } from '@apollo/client'
import tailpipeApolloLinks from './tailpipe-link'
import wordpressApolloLinks from './wordpress-link'
import listingsApolloLinks from './listings-link'

import { retryLink } from './retry-link'
import isEmpty from 'lodash/isEmpty'

export const APOLLO_STATE_PROP_NAME = 'APOLLO_INITIAL_STATE'

let apolloClient

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  }
}

const defaultOptionsSSR = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  }
}

/**
 * Switches between Tailpipe, WordPress or Listings Endpoints.
 */
const link = split(
  (operation) => {
    return operation.getContext()?.tailpipe
  },
  tailpipeApolloLinks,
  split(
    (operation) => {
      return operation.getContext()?.listing
    },
    listingsApolloLinks,
    wordpressApolloLinks
  )
)

function createApolloClient(ctx) {
  let headers = {} // initialise headers as empty

  if (!isEmpty(ctx)) {
    headers = {
      ...ctx.req?.headers
    }
  }
  const authLink = new ApolloLink((operation, forward) => {
    // Attach query information to help debug GraphQL exceptions
    operation.setContext(() => {
      return {
        headers: {
          ...headers
        }
      }
    })
    return forward(operation)
  })

  return new ApolloClient({
    ssrMode: true,
    link: retryLink.concat(authLink).concat(link),
    credentials: 'include',
    cache: new InMemoryCache(),
    headers: !isEmpty(headers) ? headers : {}, // fill in the headers so the link above will include it
    defaultOptions: typeof window === 'undefined' ? defaultOptionsSSR : defaultOptions
  })
}

export const initOnContext = (ctx) => {
  const inAppContext = Boolean(ctx.ctx)
  // Initialize ApolloClient if not already done
  const apolloClient = ctx.apolloClient || initializeApollo(ctx.apolloState || {})

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient
  }

  return ctx
}

export function initializeApollo(initialState = null, ctx = {}) {
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return createApolloClient(ctx)

  const _apolloClient = apolloClient ?? createApolloClient(ctx)
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (!isEmpty(initialState)) {
    // Get existing cache, loaded during client side data fetching
    // Restore the cache with the merged data
    _apolloClient.cache.restore(initialState)
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (client && pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }
  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}

export const previewSupportedClient = (ctx) => {
  const { req, res, preview } = ctx || {}
  if (!preview) {
    return initializeApollo()
  }

  const { previewData } = ctx
  if (!req && !res) {
    // this is a staticProps with preview mode on
    const staticCtx = previewData?.cookie
      ? { ...ctx, req: { headers: { ...previewData } } }
      : undefined
    return staticPreviewClient(staticCtx || {})
  } else {
    // this is a serverSideProps
    // include preview id in the cookie too
    const currentCookies = ctx?.req?.headers?.cookie || ''
    const dynamicCtx = {
      ...ctx,
      req: {
        ...ctx?.req,
        headers: {
          ...ctx?.req?.headers,
          cookie: `preview_id=${previewData.previewId}; ${currentCookies};`
        }
      }
    }
    return dynamicPreviewClient(dynamicCtx)
  }
}

export const dynamicPreviewClient = (ctx) => {
  return initializeApollo(null, ctx)
}

export const staticPreviewClient = (ctx) => {
  const { preview } = ctx
  return initializeApollo(null, preview ? ctx : {})
}

// exporting default since every pages currently uses default import method for apolloClient as client
export default initializeApollo()
