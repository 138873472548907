import cx from 'classnames'
import isArray from 'lodash/isArray'
import styles from './ad-impression-track.module.scss'

function AdImpressionTrack({ impressionTrackingUrls }) {
  return (
    <>
      {impressionTrackingUrls &&
        isArray(impressionTrackingUrls) &&
        impressionTrackingUrls?.map((trackingUrl, index) => {
          return (
            <img
              src={trackingUrl}
              width='1'
              height='1'
              className={cx('gam-impression-tracker', styles['drive-ad-impression'])}
              key={index}
            />
          )
        })}
    </>
  )
}

export default AdImpressionTrack
