import DriveImageFragment from '../../image'
import ReviewsMetaFragment from '../reviewsMeta'
import Disqus from '../../disqus'

const ReviewsFragment = `
fragment ReviewsFragment on Review {
    databaseId
    uri
    title
    date
    excerpt
    stickyPosts {
      stickyType
    }
    tags {
      nodes {
        id
        name
      }
    }
    large: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    mediumThumbnail: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    thumbnail: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    singleReview {
      ...ReviewsMetaFragment
    }
    attachedVehicles {
      vehicleRating {
        overall
      }
    }
    author {
      node {
        name
        uri
        slug
        avatar {
          cloudinary {
            cloudName
            deliveryType
            publicId
          }
          url
        }
      }
    }
    ${Disqus}
    categories: reviewsFormat(first: 1) {
      nodes {
        name
      }
    }
}
 ${ReviewsMetaFragment}
 ${DriveImageFragment}
`

export const ReviewsCardFragment = `
fragment ReviewsCardFragment on Review {
    databaseId
    uri
    title
    date
    stickyPosts {
      stickyType
    }
    tags {
      nodes {
        id
        name
      }
    }
    large: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    mediumThumbnail: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    thumbnail: featuredImage {
      node {
        ...DriveImageFragment
      }
    }
    singleReview {
      ...ReviewsMetaFragment
    }
    attachedVehicles {
      vehicleRating {
        efficiency
        fitness
        handling
        infotainment
        interior
        overall
        performance
        ride
        safety
        technology
        value
      }
    }
    author {
      node {
        name
        uri
        slug
        avatar {
          cloudinary {
            cloudName
            deliveryType
            publicId
          }
          url
        }
      }
    }
    ${Disqus}
    categories: reviewsFormat(first: 1) {
      nodes {
        name
      }
    }
}
${ReviewsMetaFragment}
${DriveImageFragment}
`

export default ReviewsFragment
