const DriveImageFragment = `
fragment DriveImageFragment on MediaItem {
  id
  altText
  caption
  databaseId
  sourceUrl
  title
  cloudinary {
    cloudName
    publicId
    deliveryType
  }
}
`

export default DriveImageFragment
