import { FunctionComponent, useContext, useEffect } from 'react'
import { PageContext } from '../../../utils/contexts/pageContext'

import AdImpressionTrack from '../../common/ad-units/ad-impression-track'
import { getAdUnit } from '../../common/ad-units/adTaglessFeature'
import Link, { linkDefaultProps } from '../../common/link'
import ThumbnailImage from '../../common/thumbnail-image'

export const SideMenuFeaturedAd: FunctionComponent<SideMenuFeatureAdProps> = ({
  slot,
  onRender,
  classNames,
  sidemenuAdKey
}) => {
  const { pageStore, updateStore } = useContext(PageContext)

  let ad: AdData = null
  const sidemenuAds = pageStore?.sidemenuAds
  if (sidemenuAds) {
    ad = sidemenuAds[sidemenuAdKey] ?? null
  }

  useEffect(() => {
    if (ad) {
      return
    }
    const fetchGetAdUnit = async () => {
      try {
        const adResponse: AdResponse = (await getAdUnit({ slot: slot })) ?? null
        let adDataObj: AdData = null

        if (adResponse) {
          const { image, impressionTrackingUrls, isExternalURL, url } = adResponse
          adDataObj = {
            featuredImage: image,
            isExternal: isExternalURL,
            impressionTrackingUrls: impressionTrackingUrls,
            url: url
          }

          const newSidemenuAds = {
            ...sidemenuAds,
            [sidemenuAdKey]: adDataObj
          }

          updateStore('sidemenuAds', newSidemenuAds)
        }
        onRender && onRender(slot, true, adDataObj)
      } catch (error) {
        onRender && onRender(slot, false, error)
      }
    }
    fetchGetAdUnit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ad?.featuredImage) {
    // dont render anything if adData is null
    return null
  }

  return (
    <>
      {ad && (
        <div className={classNames + ' flex'}>
          {/* only render link if link is valid */}
          {ad?.url ? (
            <Link {...linkDefaultProps} isExternal={true} href={ad?.url}>
              <a>
                <figure>
                  <ThumbnailImage image={ad.featuredImage} />
                </figure>
              </a>
            </Link>
          ) : (
            <figure>
              <ThumbnailImage image={ad.featuredImage} />
            </figure>
          )}
          <AdImpressionTrack impressionTrackingUrls={ad.impressionTrackingUrls} />
        </div>
      )}
    </>
  )
}

type SideMenuFeatureAdProps = {
  slot: string
  onRender?: Function
  classNames?: string
  sidemenuAdKey: string
}

type AdResponse = {
  id?: string
  image?: { [key: string]: any }
  impressionTrackingUrls?: string[]
  isExternalURL?: boolean
  label?: string
  url?: string
} | null

type AdData = {
  [key: string]: any
} | null

export default SideMenuFeaturedAd
