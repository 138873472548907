import { FunctionComponent } from 'react'
import logger from '../../../utils/logger'
import { GridWrap } from './types'

import GridWrapPadding from './padding'
import GridWrapMargin from './margin'

/**
 * Grid Wrapper Component.
 * @type {Reusable Component}
 * @param {String} gridSize @required props grid config size.
 * @param {String} children @required props for jsx elements that will be wrapped
 * @param {String} classNames @optional props for additional wrapper classNames.
 * @param {JSX.Element} wrapWith @optional props for wrapping element
 * @param {String} gridType @optional props for grid type margin | padding type
 * @param {String} gridTop @optional props for top spacing
 * @param {String} gridBottom @optional props for bottom spacing
 * @param {String} gridLeft @optional props for left spacing
 * @param {String} gridRight @optional props for right spacing
 * @param {String} gridX @optional props for left and right spacing
 * @param {String} gridY @optional props for top and bottom spacing
 * @param {String} fallback @optional props for fallback to gridsize spacing if not mentioned in top, bottom, left and right sizes
 * @param {String} onclick @optional props for function onclick callback
 * @returns {JSX.Element}
 */
const GridWrapper: FunctionComponent<GridWrapperProps> = (props) => {
  const { gridType, gridTop, gridBottom, gridLeft, gridRight, gridX, gridY } = props

  const isProduction: boolean = process.env.NODE_ENV === 'production'

  const logThrowError = (message: string): void => {
    if (isProduction) {
      logger.error(`[Style Error] - gridWrapper - ${message}`)
    } else {
      throw SyntaxError(message)
    }
  }

  if ((gridTop && gridY) || (gridBottom && gridY)) {
    logThrowError('Cannot set top or bottom and top|bottom Y as a prop at the same time')
  }

  if ((gridLeft && gridX) || (gridRight && gridX)) {
    logThrowError('Cannot set left or right and top|bottom Y as a prop at the same time')
  }

  if (gridType === 'margin') {
    return <GridWrapMargin {...props} />
  }

  return <GridWrapPadding {...props} />
}

export interface GridWrapperProps extends GridWrap {
  gridType?: 'margin' | 'padding'
}
export default GridWrapper
