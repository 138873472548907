import { useEffect, useLayoutEffect, useRef } from 'react'

export const useIsomorphicLayoutEffect = process.isBrowser ? useLayoutEffect : useEffect

const isBrowser = typeof window !== 'undefined'
const zeroPosition = { x: 0, y: 0 }

const getClientRect = (element) => element?.getBoundingClientRect()

export const getScrollPosition = ({ element, useWindow, boundingElement }) => {
  if (!isBrowser) {
    return zeroPosition
  }

  if (useWindow) {
    return { x: window.scrollX, y: window.scrollY }
  }

  const targetPosition = getClientRect(element?.current || document.body)
  const containerPosition = getClientRect(boundingElement?.current)

  if (!targetPosition) {
    return zeroPosition
  }

  return containerPosition
    ? {
        x: (containerPosition.x || 0) - (targetPosition.x || 0),
        y: (containerPosition.y || 0) - (targetPosition.y || 0)
      }
    : { x: targetPosition.left, y: targetPosition.top }
}

/**
 * tracks scroll position
 * @returns remove listener fuction
 */
export const useScrollPosition = (effect, deps, element, useWindow, wait, boundingElement) => {
  const position = useRef(getScrollPosition({ useWindow, boundingElement }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow, boundingElement })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  const handleScroll = () => {
    if (wait) {
      if (throttleTimeout === null) {
        throttleTimeout = window.setTimeout(callBack, wait)
      }
    } else {
      callBack()
    }
  }

  const removeEventListener = () => {
    if (boundingElement && boundingElement.current) {
      boundingElement.current.removeEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return undefined
    }

    if (boundingElement && boundingElement.current) {
      boundingElement.current.addEventListener('scroll', handleScroll, { passive: true })
    } else {
      window.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      removeEventListener()
      if (throttleTimeout) {
        clearTimeout(throttleTimeout)
      }
    }
  }, deps)

  return removeEventListener
}

useScrollPosition.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
  boundingElement: false
}
