/**
 * External dependencies.
 */
import PropTypes from 'prop-types'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
const isTest = isTestEnv()
/**
 * Logo component.
 */
const Logo = ({ className = '', id, ...restProps }) => {
  return (
    <svg
      data-testid={isTest ? 'logo' : undefined}
      id={id}
      version='1.1'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 772.1 176.33'
      fill='none'
      {...restProps}
    >
      <path
        d='M759.31,27.45H641.08V0L772.1,0.02L759.31,27.45z M641.08,176.33h118.23l12.79-27.42l-131.02-0.02V176.33z
        M714.6,74.46l-73.52-0.02v27.45h60.73L714.6,74.46z M56.52,0H0v88.16h27.45V27.45h29.07c33.48,0,60.72,27.24,60.72,60.72
        c0,33.48-27.24,60.72-60.72,60.72l-16.27,0L27.46,176.3l29.06,0.02c48.69,0,88.16-39.47,88.16-88.16C144.68,39.47,105.2,0,56.52,0z
        M360.56,176.33h27.45V0h-27.45V176.33z M514.55,111.38L462.61,0h-30.28l82.22,176.33L596.77,0h-30.28L514.55,111.38z M250.58,0
        l-84.19,0l12.8,27.45l71.39,0c16.77,0,30.36,13.59,30.36,30.36c0,16.77-13.59,30.36-30.36,30.36l-43.08,0l41.11,88.16h30.28
        l-28.31-60.71c31.87,0,57.8-25.93,57.8-57.81C308.38,25.93,282.45,0,250.58,0z'
        fill='#FFF'
      />
    </svg>
  )
}

Logo.protoTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
}

export default Logo
