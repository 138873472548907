import { getHero } from '../../../fragments/hero'
import DriveImageFragment from '../../../fragments/image'

const ReviewsMetaFragment = `
fragment ReviewsMetaFragment on Review_Singlereview {
    reviewsMeta {
      ${getHero(true)}
    }
}
 ${DriveImageFragment}
`

export default ReviewsMetaFragment

export const ReviewTypes = `
reviewTypes {
  nodes {
    id
    name
    uri
    iconField: taxonomyFields {
      iconName
    }
  }
}
`
