import isEmpty from 'lodash/isEmpty'
import parseJSONSafe from './parseJSONSafe'
const parseAttributes = (block: any) => {
  if (!block) {
    return
  }
  if (!isEmpty(block.attributesJSON)) {
    block['attributes'] = parseJSONSafe(block.attributesJSON, 'attributesJSON', {})
    delete block['attributesJSON']
  } else {
    block['attributes'] = {}
  }
}

const parseBlockAttributes = (blocks: any[] | any) => {
  if (Array.isArray(blocks)) {
    blocks.forEach((block) => {
      parseAttributes(block)
      if (block.innerBlocks) {
        parseBlockAttributes(block.innerBlocks)
      }
    })
  } else if (blocks) {
    parseAttributes(blocks)
    if (blocks.innerBlocks) {
      parseBlockAttributes(blocks.innerBlocks)
    }
  }
}

export default parseBlockAttributes
