import SideMenu from './sideMenu'
import { FunctionComponent, useEffect, useState } from 'react'
import styles from './navbar.module.scss'
import cx from 'classnames'
import { Edge } from './navbar-with-props'

const SideMenuContainer: FunctionComponent<SideMenuContainerProps> = ({
  showSideMenu,
  sideMenuData,
  checkboxTrackId
}) => {
  const [isMounted, setIsmounted] = useState<boolean>(false)

  useEffect(() => {
    setIsmounted(true)
  }, [])

  return (
    <div className={cx([styles['drive-nav-wrapper__side-wrapper']])}>
      {/*fallback supports side-content toggle by checkbox when js is diabled.
        hide when mounted as it causes side menu not able to close if user navigates to a page where
        side menu was opened via browser back button. This issue is due input checked state being
        cached by browser.
      */}
      {!isMounted && (
        <input
          type='checkbox'
          name={checkboxTrackId}
          id={checkboxTrackId}
          defaultChecked={showSideMenu}
        ></input>
      )}
      <div
        className={cx([styles['drive-nav-wrapper__side-wrapper__side-content']], {
          // fallback for input:checked css selector not working after back button pressed from browser
          [styles['drive-nav-wrapper__side-wrapper__side-content--show']]: showSideMenu
        })}
      >
        <SideMenu menuData={sideMenuData} isOpen={showSideMenu} />
      </div>
    </div>
  )
}

type SideMenuContainerProps = {
  showSideMenu: boolean
  sideMenuData: Edge[]
  checkboxTrackId: string
}
export default SideMenuContainer
